import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import HeroHome from "../partials/HeroHome";
import Services from "../partials/Services";
import Cta from "../partials/Cta";
import Footer from "../partials/Footer";
import SectionDivider from "../partials/SectionDivider";
import TestimonialCards from "../partials/TestimonialCards";
import OurToolkit from "../partials/OurToolkit";
import Values from "../partials/Values";
// import Zezaworks from "../partials/howzezaworks";
// import Toolkit from "../partials/Toolkit"
// import Toolkit2 from "../partials/Toolkit2"
// import Toolkit3 from "../partials/Toolkit3"

// let sectionStyles = {

//     minHeight: "min(47rem, 100vh)",
//     display: "grid",
//     placeContent: "center",
//     scrollSnapAlign: "start",
// };
function Home() {
  const location = useLocation();
  const values = useRef("");
  const toolkit = useRef("");
  const testimonials = useRef("");
  const [selectedtab, setselectedtab] = useState("");
  useEffect(() => {
    if (location.query) {
      setselectedtab(location.query.selected);
      if (selectedtab === "values") {
        valuesScroll();
      } else if (selectedtab === "toolkit") {
        toolkitScroll();
      } else if (selectedtab === "testimonials") {
        testimonialsScroll();
      }
    } else {
      setselectedtab("");
    }
  }, [selectedtab, location.query]);

  const toolkitScroll = () => toolkit.current.scrollIntoView();
  const testimonialsScroll = () => testimonials.current.scrollIntoView();
  const valuesScroll = () => values.current.scrollIntoView();

  return (
    <div
      // style={{ scrollBehaviour: "smooth" }}
      className="flex flex-col min-h-screen overflow-hidden"
    >
      {/*  Site header */}

      {/*  Page content */}
      <main
        // className="flex-grow relative"
        style={{
          // scrollSnapType: "y mandatory",
          // overflowY: "scroll",
          // height: "100vh",
          overflowX: "hidden",
        }}
      >
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>
        <Header />
        <HeroHome />
        <SectionDivider />
        <Services />
        <SectionDivider />
        <div ref={toolkit}>
          <OurToolkit />
        </div>
        <SectionDivider />
        <div ref={values}>
          <Values />
        </div>
        <div ref={testimonials}>
          <TestimonialCards />
        </div>
        <Cta />
        <Footer />
      </main>
    </div>
  );
}

export default Home;
