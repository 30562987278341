import React from "react";
import logstash from "../../../images/Peekinourtoolkit/Artboard 17.png";
import kibana from "../../../images/Peekinourtoolkit/Artboard 66.png";
import quicksight from "../../../images/Peekinourtoolkit/Artboard 16.png";
import mysql from "../../../images/Peekinourtoolkit/Artboard 20.png";
import Sqoop from "../../../images/Peekinourtoolkit/Artboard 19.png";
import dvc from "../../../images/Peekinourtoolkit/Artboard 23.png";
import zgboost from "../../../images/Peekinourtoolkit/Artboard 26.png";
import scikit from "../../../images/Peekinourtoolkit/Artboard 24.png";
import looker from "../../../images/Peekinourtoolkit/Artboard 27.png";
import pycarets from "../../../images/Peekinourtoolkit/Artboard 21.png";
import spacy from "../../../images/Peekinourtoolkit/Artboard 25.png";
import aws from "../../../images/Peekinourtoolkit/Artboard 28.png";
import nginx from "../../../images/Peekinourtoolkit/Artboard 29.png";
import sumologic from "../../../images/Peekinourtoolkit/Artboard 31.png";
import pyspark from "../../../images/Peekinourtoolkit/Artboard 22.png";

let toolkit1 = [
  {
    logo: quicksight,
    name: "quicksight",
    url: "https://aws.amazon.com/quicksight/",
  },
  {
    logo: logstash,
    name: "logstash",
    url: "https://www.elastic.co/logstash/",
  },
  {
    logo: kibana,
    name: "kibana",
    url: "https://www.elastic.co/kibana/",
  },
  {
    logo: Sqoop,
    name: "Sqoop",
    url: "https://sqoop.apache.org/",
  },
  {
    logo: mysql,
    name: "mysql",
    url: "https://www.mysql.com/",
  },
  { logo: pycarets, name: "pycaret", url: "https://pycaret.org/" },
  { logo: pyspark, name: "pyspark", url: "https://spark.apache.org/" },
  {
    logo: dvc,
    name: "dvc",
    url: "https://dvc.org/",
  },
  {
    logo: scikit,
    name: "scikit",
    url: "https://scikit-learn.org/stable/",
  },
  { logo: spacy, name: "spacy", url: "https://spacy.io/" },
  {
    logo: zgboost,
    name: "Xgboost",
    url: "https://xgboost.readthedocs.io/en/stable/#",
  },
  {
    logo: looker,
    name: "looker",
    url: "https://looker.com/",
  },
  { logo: aws, name: "aws", url: "https://aws.amazon.com/" },
  { logo: nginx, name: "nginx", url: "https://www.nginx.com/" },
  { logo: sumologic, name: "sumologic", url: "https://www.sumologic.com/" },
];

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

function mobiletoolkit2() {
  return (
    <div className="grid justify-items-center gap-10 grid-cols-3 ">
      {toolkit1.map(({ logo, url, name }) => (
        <div className="flex flex-col items-center justify-center">
          <div
            key={logo}
            onClick={() => openInNewTab(url)}
            className="w-14 h-14  cursor-pointer"
          >
            <img src={logo} alt="logo" />
          </div>
          <nobr>
            <span className="text-center capitalize font-semibold pt-2 text-xs">
              {name}
            </span>
          </nobr>
        </div>
      ))}
    </div>
  );
}

export default mobiletoolkit2;
