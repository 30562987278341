import React, { useState } from "react";
import PropTypes from "prop-types";
import Transition from "../utils/Transition.js";

function Dropdown({ children, title, currentroute }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <li
      className="relative"
      onMouseEnter={() => setDropdownOpen(true)}
      onMouseLeave={() => setDropdownOpen(false)}
      onFocus={() => setDropdownOpen(true)}
      onBlur={() => setDropdownOpen(false)}
    >
      <button
        className={
          currentroute === "/expertise"
            ? '"text-white hover:text-white dark:text-white dark:hover:text-white text-xl px-5 py-2 flex items-center font-bold transition duration-150 ease-in-out focus:outline-none"'
            : "text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 text-xl px-5 py-2 flex items-center transition duration-150 ease-in-out focus:outline-none"
        }
        aria-expanded={dropdownOpen}
        onClick={(e) => e.preventDefault()}
      >
        {title}
      </button>
      <Transition
        show={dropdownOpen}
        tag="ul"
        className="origin-top-right absolute top-full left-0 w-52 bg-gray-800 dark:bg-gray-800  shadow-lg py-2 ml-1 rounded"
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        {children}
      </Transition>
    </li>
  );
}

export default Dropdown;

Dropdown.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired,
  ]),
  title: PropTypes.string.isRequired,
};
