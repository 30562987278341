import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import datamining from "../../images/Expertise/Data Mining.png";
import deeplearning from "../../images/Expertise/Deep Learning.png";
import observe from "../../images/Expertise/Observability ML.png";
let title = " Machine Learning";
let subtitle = "Extract intelligence from your data.";
let data = [
  {
    title: "Data Mining",
    img: datamining,
    subheading:
      "We uncover patterns and generate insights using statistics, data analytics and machine learning algorithms.",
    list: [
      { title: " Exploratory Data Analysis." },
      { title: "Anomaly and Fraud Detection" },
      { title: "Clustering/Segmentation" },
      { title: "Classification, Prediction and Forecasting" },
    ],
    showline: true,
  },
  {
    title: "Deep Learning",
    img: deeplearning,
    subheading:
      "We experiment, train, and fine tune deep-learning models to identify the most optimum and performant ones.",
    list: [
      { title: " Natural Language Processing" },
      { title: "Computer Vision" },
      { title: "Recommendations" },
    ],
    showline: true,
  },
  {
    title: "MLOps",
    img: observe,
    subheading:
      "We implement end-to-end designing, building and managing reproducible, testable, and evolvable ML-powered software — Data, ML models, and Code.",
    list: [
      { title: "Sync ML and software application release cycles." },
      {
        title:
          "Automate training and testing of machine learning artifacts(e.g. data validation, ML model testing, and ML model integration testing). ",
      },
      { title: " Integrate datasets and models into CI/CD pipelines." },
    ],
    showline: false,
  },
];

function Machinelearning() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="max-w-84rem mx-auto  px-4 sm:px-6">
      <div className="flex flex-col items-start justify-center pb-20">
        <span className=" h2 md:h1 mb-2 md:mb-4 pt-20">{title}</span>
        <span className="text-xl md:text-2xl text-gray-600 dark:text-gray-400 mb-4 md:mb-8 py-2">
          {subtitle}
        </span>
        <Link
          to="/contact"
          className="btn-sm text-center text-white bg-teal-500 text-xl transform hover:scale-110 motion-reduce:transform-none px-20 mb-36"
        >
          Let’s Talk
        </Link>
      </div>

      <div className="flex flex-col items-center justify-center py-12 md:pb-28">
        <div className="md:hidden">
          <svg
            width="302"
            height="1"
            viewBox="0 0 302 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="0.5"
              x2="302"
              y2="0.5"
              stroke="url(#paint0_radial_56_356)"
            />
            <defs>
              <radialGradient
                id="paint0_radial_56_356"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(285.437 1.54517) rotate(-180) scale(295.109 1.5244e+06)"
              >
                <stop stop-color="#39BAB5" stop-opacity="0" />
                <stop offset="0.497917" stop-color="#39BAB5" />
                <stop offset="1" stop-color="#39BAB5" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>
        <div className="hidden md:flex">
          <svg
            width="616"
            height="1"
            viewBox="0 0 616 1"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="0.5"
              x2="616"
              y2="0.5"
              stroke="url(#paint0_radial_1036_2371)"
            />
            <defs>
              <radialGradient
                id="paint0_radial_1036_2371"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(582.216 1.54517) rotate(-180) scale(601.944 1.5244e+06)"
              >
                <stop stop-color="#726E6F" stop-opacity="0" />
                <stop offset="0.497917" stop-color="#726E6F" />
                <stop offset="1" stop-color="#726E6F" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>

      {data.map(({ title, subheading, list, img, showline }) => (
        <div key={title} className="md:pt-10">
          <div className=" flex flex-col-reverse md:flex-row items-center justify-between">
            <div className="w-full flex flex-col md:items-center md:block md:w-2/3">
              <span className="text-white font-bold h2 mb-4 md:mb-8 md:py-12">
                {title}
              </span>
              <div className="text-opacity-70 pt-10 text-lg md:text-xl tracking-tighter font-medium text-gray-400 dark:text-gray-400">
                {subheading}
              </div>
              <ul className="list-disc text-xl md:text-2xl text-teal-600 pl-5 md:pl-7 pt-10">
                {list.map(({ title }) => (
                  <li key={title}>
                    <span className="text-lg md:text-xl text-gray-400 dark:text-gray-400 font-medium">
                      {title}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full mb-10 md:mb-0 md:w-1/3 flex items-center justify-center">
              <img
                src={img}
                alt="icon"
                className="w-6/12 h-6/12 md:w-full md:h-full"
              />
            </div>
          </div>

          {showline ? (
            <div className="flex flex-col items-center justify-center py-16 md:py-32">
              <div>
                <div className="md:hidden">
                  <svg
                    width="302"
                    height="1"
                    viewBox="0 0 302 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      y1="0.5"
                      x2="302"
                      y2="0.5"
                      stroke="url(#paint0_radial_56_356)"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial_56_356"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(285.437 1.54517) rotate(-180) scale(295.109 1.5244e+06)"
                      >
                        <stop stop-color="#39BAB5" stop-opacity="0" />
                        <stop offset="0.497917" stop-color="#39BAB5" />
                        <stop
                          offset="1"
                          stop-color="#39BAB5"
                          stop-opacity="0"
                        />
                      </radialGradient>
                    </defs>
                  </svg>
                </div>
                <div className="hidden md:flex">
                  <svg
                    width="616"
                    height="1"
                    viewBox="0 0 616 1"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      y1="0.5"
                      x2="616"
                      y2="0.5"
                      stroke="url(#paint0_radial_1036_2371)"
                    />
                    <defs>
                      <radialGradient
                        id="paint0_radial_1036_2371"
                        cx="0"
                        cy="0"
                        r="1"
                        gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(582.216 1.54517) rotate(-180) scale(601.944 1.5244e+06)"
                      >
                        <stop stop-color="#726E6F" stop-opacity="0" />
                        <stop offset="0.497917" stop-color="#726E6F" />
                        <stop
                          offset="1"
                          stop-color="#726E6F"
                          stop-opacity="0"
                        />
                      </radialGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      ))}
      <div className="flex items-center justify-center py-28">
        {/* <Link
          to={{
            pathname: "/expertise",
            query: { selected: "Data Engineering" },
          }}
          className="py-1 md:py-2 px-2 md:px-8 border border-gray-500 rounded-lg
          hover:bg-teal-500 flex items-center space-x-3"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          <span className="text-xs md:text-base font-semibold text-white">
            Data Engineering
          </span>
        </Link> */}
        <Link
          to={{
            pathname: "/expertise",
            query: { selected: "Business Intelligence" },
          }}
          className="py-1 md:py-2 px-2 md:px-8 border border-gray-500 rounded-lg
          hover:bg-teal-500 flex items-center space-x-3"
        >
          <span className="text-xs md:text-base font-semibold text-white">
            More Expertise
          </span>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </div>
        </Link>
      </div>
      <div className="w-full h-auto md:h-48 bg-gray-600 bg-opacity-20">
        <div className="flex flex-col md:flex-row items-center justify-evenly p-3 md:px-12 md:py-16">
          <div className="flex flex-col justify-center">
            <span className="text-2xl font-extrabold text-white mb-2 md:text-2.5xl text-center ">
              Have an idea in mind?
            </span>
          </div>
          <Link
            to="/contact"
            className="btn-sm text-center text-teal-400 hover:text-white border-5 dark:border-teal-500 hover:bg-teal-500 text-base md:text-xl my-2 px-2 md:px-10"
          >
            Let's Connect
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Machinelearning;
