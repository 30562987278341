import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Dropdown from "../utils/Dropdown";
import Transition from "../utils/Transition.js";
import heroImage from "../images/zeza_logo5.png";
// import Dropdown from "../utils/Dropdown";

function Header() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [currentroute, setcurrentroute] = useState("");
  const [currentquery, setcurrentquery] = useState("");
  // const [hidedropdown, sethidedropdown] = useState(false);
  const trigger = useRef(null);
  const mobileNav = useRef(null);
  // const location = useLocation();
  // useEffect(() => {
  //   if (location.pathname === "/expertise") {
  //     sethidedropdown(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const [lastScrollTop, setlastScrollTop] = useState();
  const [navcolor, setnavcolor] = useState(true);

  useEffect(() => {
    let navbar = document.getElementById("navbar");
    window.addEventListener("scroll", function () {
      //on every scroll this funtion will be called
      if (window.pageYOffset < 100) {
        setnavcolor(true);
      } else {
        setnavcolor(false);
      }

      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      //This line will get the location on scroll
      if (scrollTop > lastScrollTop) {
        //if it will be greater than the previous
        navbar.style.top = "-80px";
        //set the value to the negetive of height of navbar
      } else {
        navbar.style.top = "0";
      }
      setlastScrollTop(scrollTop);
      // lastScrollTop = scrollTop; //New Position Stored
    });
  }, [lastScrollTop]);

  // close the mobile menu on click outside
  const location = useLocation();
  useEffect(() => {
    if (location.query) {
      setcurrentquery(location.query.selected);
    } else {
      setcurrentquery("");
    }
    setcurrentroute(location.pathname);
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      // console.log(mobileNav.current.contains(target));
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [location.query, location.pathname, mobileNavOpen]);

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div>
      <div className="hidden md:flex">
        <header
          id="navbar"
          className={navcolor ? " w-full z-30" : "bg-bg-color w-full z-30"}
          style={{ scrollSnapAlign: "start" }}
        >
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="flex items-center justify-between h-20">
              <nav className="hidden md:flex md:flex-grow">
                <ul className="flex flex-grow flex-wrap items-center font-medium tracking-tighter">
                  <Link to="/">
                    <img
                      src={require("../images/zeza_logo.png").default}
                      width="60"
                      height="60"
                      alt="zezalogo"
                    />
                  </Link>
                  <div>
                    <Link
                      to="/"
                      className="text-gray-800  dark:text-gray-300  text-2xl flex items-center transition duration-150 ease-in-out"
                    >
                      zeza
                    </Link>
                  </div>
                </ul>

                <ul className="flex justify-end flex-wrap items-center">
                  <div>
                    <Dropdown currentroute={currentroute} title="Expertise">
                      <div>
                        <Link
                          to={{
                            pathname: "/expertise",
                            query: { selected: "Data Engineering" },
                          }}
                          className={
                            currentquery === "Data Engineering"
                              ? '"text-base font-bold  text-white dark:text-white hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"'
                              : "text-base  text-white dark:text-white hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"
                          }
                        >
                          Data Engineering
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={{
                            pathname: "/expertise",
                            query: { selected: "Machine Learning" },
                          }}
                          className={
                            currentquery === "Machine Learning"
                              ? '"text-base font-bold  text-white dark:text-white hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"'
                              : "text-base  text-white dark:text-white hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"
                          }
                        >
                          Machine Learning
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={{
                            pathname: "/expertise",
                            query: { selected: "Business Intelligence" },
                          }}
                          className={
                            currentquery === "Business Intelligence"
                              ? '"text-base font-bold  text-white dark:text-white hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"'
                              : "text-base  text-white dark:text-white hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"
                          }
                        >
                          Business Intelligence
                        </Link>
                      </div>
                      <div>
                        <Link
                          to={{
                            pathname: "/expertise",
                            query: { selected: "Infrastructure" },
                          }}
                          className={
                            currentquery === "Infrastructure"
                              ? '"text-base font-bold  text-white dark:text-white hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"'
                              : "text-base  text-white dark:text-white hover:text-teal-500 dark:hover:text-teal-500 flex py-2 px-4 leading-tight"
                          }
                        >
                          Infrastructure
                        </Link>
                      </div>
                    </Dropdown>
                  </div>

                  <div>
                    <Link
                      to="/about"
                      className={
                        currentroute === "/about"
                          ? '"text-white hover:text-white dark:text-white dark:hover:text-white text-xl px-5 py-2 flex items-center font-bold transition duration-150 ease-in-out focus:outline-none"'
                          : "text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 text-xl px-5 py-2 flex items-center transition duration-150 ease-in-out focus:outline-none"
                      }
                    >
                      About
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="/career"
                      className={
                        currentroute === "/career"
                          ? '"text-white hover:text-white dark:text-white dark:hover:text-white text-xl px-5 py-2 flex items-center font-bold transition duration-150 ease-in-out focus:outline-none"'
                          : "text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 text-xl px-5 py-2 flex items-center transition duration-150 ease-in-out focus:outline-none"
                      }
                    >
                      Careers
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="/blogs"
                      className={
                        currentroute === "/blogs"
                          ? '"text-white hover:text-white dark:text-white dark:hover:text-white text-xl px-5 py-2 flex items-center font-bold transition duration-150 ease-in-out focus:outline-none"'
                          : "text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 text-xl px-5 py-2 flex items-center transition duration-150 ease-in-out focus:outline-none"
                      }
                    >
                      Blog
                    </Link>
                  </div>
                  <div>
                    <Link
                      to="/contact"
                      className={
                        currentroute === "/contact"
                          ? "btn-sm text-white bg-teal-600 text-xl hover:bg-teal-600 ml-6"
                          : "btn-sm text-white bg-teal-500 text-xl hover:bg-teal-400 ml-6"
                      }
                    >
                      Contact
                    </Link>
                  </div>
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </div>
      <div>
        <header
          className={
            navcolor
              ? "fixed w-full z-30 md:hidden"
              : "bg-bg-color fixed w-full z-30 md:hidden"
          }
          style={{ scrollSnapAlign: "start" }}
        >
          <div className="flex items-center justify-between w-full px-4 h-20">
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && "active"}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" rx="1" />
                <rect y="11" width="24" height="2" rx="1" />
                <rect y="18" width="24" height="2" rx="1" />
              </svg>
            </button>
            <div className="sm:hidden flex justify-self items-center ml-auto">
              <Link to="/" className="flex items-center">
                <img
                  className="sm:hidden"
                  src={heroImage}
                  alt="heroImage"
                  height="58px"
                  width="58px"
                />
                <h2 className="text-2xl">zeza</h2>
              </Link>
            </div>
            <Transition
              show={mobileNavOpen}
              tag="ul"
              className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
              enter="transition ease-out duration-200 transform"
              enterStart="opacity-0 -translate-x-full"
              enterEnd="opacity-100 translate-x-0"
              leave="transition ease-out duration-200"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"
            >
              <nav
                id="mobile-nav"
                ref={mobileNav}
                className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg no-scrollbar"
              >
                <div className="py-6 pr-4 pl-20">
                  <ul className="flex flex-grow flex-wrap items-center font-medium">
                    <Link to="/">
                      <img
                        src={require("../images/zeza_logo.png").default}
                        width="62"
                        height="62"
                        alt="zezaLogo"
                      />
                    </Link>
                    <div>
                      <Link
                        to="/"
                        className="text-gray-800 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 text-2xl flex items-center transition duration-150 ease-in-out"
                      >
                        zeza
                      </Link>
                    </div>
                  </ul>

                  {/* Links */}
                  <ul>
                    <div className="pt-2">
                      <Link
                        to="/"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 text-xl dark:hover:text-gray-100 py-2 focus:outline-none"
                      >
                        Home
                      </Link>
                    </div>
                    <div className="py-2 my-2 border-t border-b border-gray-200 dark:border-gray-800">
                      <span className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 text-xl dark:hover:text-gray-100 py-2 focus:outline-none">
                        Expertise
                      </span>
                      <ul className="pl-4">
                        <div onClick={() => setMobileNavOpen(false)}>
                          <Link
                            to={{
                              pathname: "/expertise",
                              query: { selected: "Data Engineering" },
                            }}
                            className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                          >
                            Data Engineering
                          </Link>
                        </div>
                        <div onClick={() => setMobileNavOpen(false)}>
                          <Link
                            to={{
                              pathname: "/expertise",
                              query: { selected: "Machine Learning" },
                            }}
                            className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                          >
                            Machine Learning
                          </Link>
                        </div>
                        <div onClick={() => setMobileNavOpen(false)}>
                          <Link
                            to={{
                              pathname: "/expertise",
                              query: { selected: "Business Intelligence" },
                            }}
                            className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                          >
                            Business Intelligence
                          </Link>
                        </div>
                        <div onClick={() => setMobileNavOpen(false)}>
                          <Link
                            to={{
                              pathname: "/expertise",
                              query: { selected: "Infrastructure" },
                            }}
                            className="text-base flex font-medium text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-100 py-2"
                          >
                            Infrastructure
                          </Link>
                        </div>
                      </ul>
                    </div>
                    <div>
                      <Link
                        to="/about"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 text-xl dark:hover:text-gray-100 py-2 focus:outline-none"
                      >
                        About
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="/career"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 text-xl dark:hover:text-gray-100 py-2 focus:outline-none"
                      >
                        Careers
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="/blogs"
                        className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 text-xl dark:hover:text-gray-100 py-2 focus:outline-none"
                      >
                        Blog
                      </Link>
                    </div>
                    <div>
                      <Link
                        to="/contact"
                        className="font-medium w-full inline-flex items-center justify-center text-xl border border-transparent px-8 py-2 my-2 rounded text-white bg-teal-500 hover:bg-teal-400 transition duration-150 ease-in-out"
                      >
                        Contact
                      </Link>
                    </div>
                  </ul>
                </div>
              </nav>
              <div>
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                    href="https://twitter.com/zeza_tech"
                    aria-label="Twitter"
                  >
                    <svg
                      className="w-8 h-8 fill-current"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                    </svg>
                  </a>
                </div>

                {/* LINKEDIN */}
                <div className="ml-4">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                    href="https://www.linkedin.com/company/zeza-tech"
                    aria-label="Linkedin"
                  >
                    <svg
                      className="w-8 h-8 fill-current"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
                    </svg>
                  </a>
                </div>
              </div>
            </Transition>
          </div>
        </header>
      </div>
    </div>
  );
}

export default Header;
