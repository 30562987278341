import React from "react";
import vpn from "../images/blogs/vpn.jpg";
import { Link } from "react-router-dom";

function BlogList() {
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          {/*  Page header */}
          <div className="max-w-5xl pb-12 md:pb-20 text-center md:text-left">
            <h1 className="h2 md:text-4xl" data-aos="fade-up">
              Fresh from our team's oven
            </h1>
          </div>

          {/*  Featured article */}
          <div className="pb-12 md:pb-20">
            <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
              <Link
                to={{
                  pathname:
                    "https://medium.com/zeza-tech/unlocking-marketing-precision-a-deep-dive-into-channel-mix-optimization-for-enhanced-roi-983f6f0ab4d0",
                }}
                target="_blank"
                className="relative block group"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div
                  className="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none"
                  aria-hidden="true"
                ></div>
                <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                  <img
                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                    src={
                      require("../images/blogs/unlock-marketing-precision.webp")
                        .default
                    }
                    width="540"
                    height="303"
                    loading="lazy"
                    alt="Blog post 01"
                  />
                </figure>
              </Link>
              <div data-aos="fade-left" data-aos-delay="200">
                <header>
                  {/* <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Apache Spark
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Cross-platform
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Android
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          iOS
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  <p className="h4 text-xl lg:text-3xl mb-2">
                    <Link
                      to={{
                        pathname:
                          "https://medium.com/zeza-tech/unlocking-marketing-precision-a-deep-dive-into-channel-mix-optimization-for-enhanced-roi-983f6f0ab4d0",
                      }}
                      target="_blank"
                      className="hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Unlocking Marketing Precision
                    </Link>
                  </p>
                </header>
                <p className="text-lg text-gray-400 flex-grow">
                  A Deep Dive into Channel Mix Optimization for Enhanced ROI
                  Analysis
                </p>
                <div className="my-3">
                  <ul className="flex flex-wrap text-xs font-medium -m-1">
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Data Engineering
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Data Visualization
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Machine Learning
                      </Link>
                    </li>
                  </ul>
                </div>
                <footer className="flex items-center mt-4">
                  <Link to="#">
                    <img
                      className="rounded-full flex-shrink-0 mr-4"
                      src={
                        require("../images/teamMembers/prarthiv.webp").default
                      }
                      width="40"
                      height="40"
                      loading="lazy"
                      alt="Author 01"
                    />
                  </Link>
                  <div className="font-medium">
                    <Link
                      to="#"
                      className="text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Prarthiv Evan
                    </Link>
                    <span className="text-gray-700"> - </span>
                    <span className="text-gray-500">Mar 5, 2024</span>
                  </div>
                </footer>
              </div>
            </article>
          </div>
          <div className="pb-12 md:pb-20">
            <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
              <Link
                to={{
                  pathname:
                    "https://medium.com/zeza-tech/data-engineering-and-ml-platform-part-3-features-cb090d3537dd",
                }}
                target="_blank"
                className="relative block group"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div
                  className="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none"
                  aria-hidden="true"
                ></div>
                <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                  <img
                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                    src={
                      require("../images/blogs/platform-architecture-3.webp")
                        .default
                    }
                    width="540"
                    height="303"
                    loading="lazy"
                    alt="Blog post 01"
                  />
                </figure>
              </Link>
              <div data-aos="fade-left" data-aos-delay="200">
                <header>
                  {/* <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Apache Spark
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Cross-platform
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Android
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          iOS
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  <p className="h4 text-xl lg:text-3xl mb-2">
                    <Link
                      to={{
                        pathname:
                          "https://medium.com/zeza-tech/data-engineering-and-ml-platform-part-3-features-cb090d3537dd",
                      }}
                      target="_blank"
                      className="hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Data Engineering and ML Platform - Part 3 - Features
                    </Link>
                  </p>
                </header>
                <p className="text-lg text-gray-400 flex-grow">
                  Platform Architecture, Codebase, and Interesting Features
                </p>
                <div className="my-3">
                  <ul className="flex flex-wrap text-xs font-medium -m-1">
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Data Engineering
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Data Science
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Machine Learning
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        ML Engineering
                      </Link>
                    </li>
                  </ul>
                </div>
                <footer className="flex items-center mt-4">
                  <Link to="#">
                    <img
                      className="rounded-full flex-shrink-0 mr-4"
                      src={
                        require("../images/teamMembers/susmit-1.2-modified.webp")
                          .default
                      }
                      width="40"
                      height="40"
                      loading="lazy"
                      alt="Author 01"
                    />
                  </Link>
                  <div className="font-medium">
                    <Link
                      to="#"
                      className="text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Susmit Vengurlekar
                    </Link>
                    <span className="text-gray-700"> - </span>
                    <span className="text-gray-500">Feb 26, 2024</span>
                  </div>
                </footer>
              </div>
            </article>
          </div>
          <div className="pb-12 md:pb-20">
            <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
              <Link
                to={{
                  pathname:
                    "https://medium.com/zeza-tech/data-engineering-and-ml-platform-part-2-codebase-a0ce453239de",
                }}
                target="_blank"
                className="relative block group"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div
                  className="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none"
                  aria-hidden="true"
                ></div>
                <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                  <img
                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                    src={
                      require("../images/blogs/platform-architecture-2.webp")
                        .default
                    }
                    width="540"
                    height="303"
                    loading="lazy"
                    alt="Blog post 01"
                  />
                </figure>
              </Link>
              <div data-aos="fade-left" data-aos-delay="200">
                <header>
                  {/* <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Apache Spark
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Cross-platform
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Android
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          iOS
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  <p className="h4 text-xl lg:text-3xl mb-2">
                    <Link
                      to={{
                        pathname:
                          "https://medium.com/zeza-tech/data-engineering-and-ml-platform-part-2-codebase-a0ce453239de",
                      }}
                      target="_blank"
                      className="hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Data Engineering and ML Platform - Part 2 - Codebase
                    </Link>
                  </p>
                </header>
                <p className="text-lg text-gray-400 flex-grow">
                  Platform Architecture, Codebase, and Interesting Features
                </p>
                <div className="my-3">
                  <ul className="flex flex-wrap text-xs font-medium -m-1">
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Data Engineering
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Data Science
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Machine Learning
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Code
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        ML Engineering
                      </Link>
                    </li>
                  </ul>
                </div>
                <footer className="flex items-center mt-4">
                  <Link to="#">
                    <img
                      className="rounded-full flex-shrink-0 mr-4"
                      src={
                        require("../images/teamMembers/susmit-1.2-modified.webp")
                          .default
                      }
                      width="40"
                      height="40"
                      loading="lazy"
                      alt="Author 01"
                    />
                  </Link>
                  <div className="font-medium">
                    <Link
                      to="#"
                      className="text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Susmit Vengurlekar
                    </Link>
                    <span className="text-gray-700"> - </span>
                    <span className="text-gray-500">Feb 20, 2024</span>
                  </div>
                </footer>
              </div>
            </article>
          </div>
          <div className="pb-12 md:pb-20">
            <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
              <Link
                to={{
                  pathname:
                    "https://medium.com/zeza-tech/data-engineering-and-ml-platform-part-1-platform-architecture-e5d869cae94f",
                }}
                target="_blank"
                className="relative block group"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div
                  className="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none"
                  aria-hidden="true"
                ></div>
                <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                  <img
                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                    src={
                      require("../images/blogs/platform-architecture-1.webp")
                        .default
                    }
                    width="540"
                    height="303"
                    loading="lazy"
                    alt="Blog post 01"
                  />
                </figure>
              </Link>
              <div data-aos="fade-left" data-aos-delay="200">
                <header>
                  {/* <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Apache Spark
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Cross-platform
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Android
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          iOS
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  <p className="h4 text-xl lg:text-3xl mb-2">
                    <Link
                      to={{
                        pathname:
                          "https://medium.com/zeza-tech/data-engineering-and-ml-platform-part-1-platform-architecture-e5d869cae94f",
                      }}
                      target="_blank"
                      className="hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Data Engineering and ML Platform - Part 1
                    </Link>
                  </p>
                </header>
                <p className="text-lg text-gray-400 flex-grow">
                  Platform Architecture, Codebase, and Interesting Features
                </p>
                <div className="my-3">
                  <ul className="flex flex-wrap text-xs font-medium -m-1">
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Data Engineering
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Data Science
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Machine Learning
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        System Architecture
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        ML Engineering
                      </Link>
                    </li>
                  </ul>
                </div>
                <footer className="flex items-center mt-4">
                  <Link to="#">
                    <img
                      className="rounded-full flex-shrink-0 mr-4"
                      src={
                        require("../images/teamMembers/susmit-1.2-modified.webp")
                          .default
                      }
                      width="40"
                      height="40"
                      loading="lazy"
                      alt="Author 01"
                    />
                  </Link>
                  <div className="font-medium">
                    <Link
                      to="#"
                      className="text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Susmit Vengurlekar
                    </Link>
                    <span className="text-gray-700"> - </span>
                    <span className="text-gray-500">Feb 13, 2024</span>
                  </div>
                </footer>
              </div>
            </article>
          </div>
          <div className="pb-12 md:pb-20">
            <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
              <Link
                to={{
                  pathname:
                    "https://medium.com/zeza-tech/dynamic-text-extraction-using-llm-c83d41222b4d",
                }}
                target="_blank"
                className="relative block group"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div
                  className="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none"
                  aria-hidden="true"
                ></div>
                <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                  <img
                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                    src={require("../images/blogs/text-extraction.png").default}
                    width="540"
                    height="303"
                    loading="lazy"
                    alt="Blog post 01"
                  />
                </figure>
              </Link>
              <div data-aos="fade-left" data-aos-delay="200">
                <header>
                  {/* <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Apache Spark
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Cross-platform
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Android
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          iOS
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  <p className="h4 text-xl lg:text-3xl mb-2">
                    <Link
                      to={{
                        pathname:
                          "https://medium.com/zeza-tech/dynamic-text-extraction-using-llm-c83d41222b4d",
                      }}
                      target="_blank"
                      className="hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Dynamic Text Extraction Using LLM
                    </Link>
                  </p>
                </header>
                <p className="text-lg text-gray-400 flex-grow">
                  Dynamic Text Extraction functionality which uses GPT 4 code
                  generation capabilities
                </p>
                <div className="my-3">
                  <ul className="flex flex-wrap text-xs font-medium -m-1">
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        GPT 4
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        AI Code Generation
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Dynamic Text Extraction
                      </Link>
                    </li>

                    {/* <li className="m-1">
                        <Link to="#" className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500">Page Speed</Link>
                      </li> */}
                  </ul>
                </div>
                <footer className="flex items-center mt-4">
                  <Link to="#">
                    <img
                      className="rounded-full flex-shrink-0 mr-4"
                      src={require("../images/teamMembers/vivek.png").default}
                      width="40"
                      height="40"
                      loading="lazy"
                      alt="Author 01"
                    />
                  </Link>
                  <div className="font-medium">
                    <Link
                      to="#"
                      className="text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Vivek Vedant
                    </Link>
                    <span className="text-gray-700"> - </span>
                    <span className="text-gray-500">Feb 5, 2024</span>
                  </div>
                </footer>
              </div>
            </article>
          </div>
          <div className="pb-12 md:pb-20">
            <article className="max-w-sm mx-auto md:max-w-none grid md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 xl:gap-16 items-center">
              <Link
                to={{
                  pathname:
                    "https://medium.com/zeza-tech/our-journey-with-xcellens-xboost-personalizing-pharma-strategies-for-hcp-excellence-d79e9b08be77",
                }}
                target="_blank"
                className="relative block group"
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <div
                  className="absolute inset-0 bg-gray-800 hidden md:block transform md:translate-y-2 md:translate-x-4 xl:translate-y-4 xl:translate-x-8 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out pointer-events-none"
                  aria-hidden="true"
                ></div>
                <figure className="relative h-0 pb-9/16 md:pb-3/4 lg:pb-9/16 overflow-hidden transform md:-translate-y-2 xl:-translate-y-4 group-hover:translate-x-0 group-hover:translate-y-0 transition duration-700 ease-out">
                  <img
                    className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                    src={require("../images/blogs/xboost.png").default}
                    width="540"
                    height="303"
                    loading="lazy"
                    alt="Blog post 01"
                  />
                </figure>
              </Link>
              <div data-aos="fade-left" data-aos-delay="200">
                <header>
                  {/* <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Apache Spark
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Cross-platform
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Android
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          iOS
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  <p className="h4 text-xl lg:text-3xl mb-2">
                    <Link
                      to={{
                        pathname:
                          "https://medium.com/zeza-tech/our-journey-with-xcellens-xboost-personalizing-pharma-strategies-for-hcp-excellence-d79e9b08be77",
                      }}
                      target="_blank"
                      className="hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Our Journey with Xcellen’s XBoost
                    </Link>
                  </p>
                </header>
                <p className="text-lg text-gray-400 flex-grow">
                  Personalizing Pharma Strategies for HCP Excellence
                </p>
                <div className="my-3">
                  <ul className="flex flex-wrap text-xs font-medium -m-1">
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Pharma
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        No Code ML Tools
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        CRM Solution
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Data Analysis
                      </Link>
                    </li>
                    <li className="m-1">
                      <Link
                        to="#"
                        className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                      >
                        Marketing
                      </Link>
                    </li>

                    {/* <li className="m-1">
                        <Link to="#" className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500">Page Speed</Link>
                      </li> */}
                  </ul>
                </div>
                <footer className="flex items-center mt-4">
                  <Link to="#">
                    <img
                      className="rounded-full flex-shrink-0 mr-4"
                      src={
                        require("../images/teamMembers/prarthiv.webp").default
                      }
                      width="40"
                      height="40"
                      loading="lazy"
                      alt="Author 01"
                    />
                  </Link>
                  <div className="font-medium">
                    <Link
                      to="#"
                      className="text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Prarthiv Evan Poudyal
                    </Link>
                    <span className="text-gray-700"> - </span>
                    <span className="text-gray-500">Jan 25, 2024</span>
                  </div>
                </footer>
              </div>
            </article>
          </div>

          {/*  Articles list */}
          <div className="max-w-sm mx-auto md:max-w-none">
            {/*  Section title */}
            <h4
              className="h4 pb-6 mb-10 border-b border-gray-700"
              data-aos="fade-up"
            >
              Latest articles
            </h4>

            {/*  Articles container */}
            <div className="grid gap-12 md:grid-cols-3 md:gap-x-6 md:gap-y-8 items-start">
              {/*  1st article */}
              <article
                className="flex flex-col h-full"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <header>
                  <Link
                    to={{
                      pathname:
                        "https://medium.com/zeza-tech/using-date-time-and-date-time-features-in-ml-96970be72329",
                    }}
                    target="_blank"
                    className="block mb-6"
                  >
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img
                        className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                        src={require("../images/blogs/latestBlog.jpg").default}
                        width="352"
                        height="198"
                        loading="lazy"
                        alt="News 02"
                      />
                    </figure>
                  </Link>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Data Science
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Data Engineering
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Encoding
                        </Link>
                      </li>
                      {/* <li className="m-1">
                        <Link to="#" className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500">Page Speed</Link>
                      </li> */}
                    </ul>
                  </div>
                  <h3 className="h4 mb-2">
                    <Link
                      to={{
                        pathname:
                          "https://medium.com/zeza-tech/using-date-time-and-date-time-features-in-ml-96970be72329",
                      }}
                      target="_blank"
                      className="hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Using date, time, and date-time features in ML
                    </Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-400 flex-grow">
                  A guide on encoding and engineering date time features
                </p>
                <footer className="flex items-center mt-4">
                  <Link to="#">
                    <img
                      className="rounded-full flex-shrink-0 mr-4"
                      src={
                        require("../images/teamMembers/susmit-1.2-modified.webp")
                          .default
                      }
                      width="40"
                      height="40"
                      loading="lazy"
                      alt="Author 02"
                    />
                  </Link>
                  <div className="font-medium">
                    <Link
                      to="#"
                      className="text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Susmit Vengurlekar
                    </Link>
                    <span className="text-gray-700"> - </span>
                    <span className="text-gray-500">May 20, 2022</span>
                  </div>
                </footer>
              </article>
              {/*  2nd article */}
              <article className="flex flex-col h-full" data-aos="fade-up">
                <header>
                  <Link
                    to={{
                      pathname:
                        "https://medium.com/zeza-tech/apache-spark-architecture-411565908a1",
                    }}
                    target="_blank"
                    className="block mb-6"
                  >
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img
                        className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                        src={require("../images/blogs/apache.png").default}
                        width="352"
                        height="198"
                        loading="lazy"
                        alt="News 01"
                      />
                    </figure>
                  </Link>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Apache Spark
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Spark Architecture
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Big Data
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <h3 className="h4 mb-2">
                    <Link
                      to={{
                        pathname:
                          "https://medium.com/zeza-tech/apache-spark-architecture-411565908a1",
                      }}
                      target="_blank"
                      className="hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Apache Spark Architecture
                    </Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-400 flex-grow">
                  Commence on the path of becoming a sparkling big data engineer
                </p>
                <footer className="flex items-center mt-4">
                  <Link to="#">
                    <img
                      className="rounded-full flex-shrink-0 mr-4"
                      src={
                        require("../images/teamMembers/susmit-1.2-modified.webp")
                          .default
                      }
                      width="40"
                      height="40"
                      loading="lazy"
                      alt="Author 01"
                    />
                  </Link>
                  <div className="font-medium">
                    <Link
                      to="#"
                      className="text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Susmit Vengurlekar
                    </Link>
                    <span className="text-gray-700"> - </span>
                    <span className="text-gray-500">Nov 8, 2021</span>
                  </div>
                </footer>
              </article>
              {/*  3rd article */}
              <article
                className="flex flex-col h-full"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <header>
                  <Link
                    to={{
                      pathname:
                        "https://medium.com/zeza-tech/secure-your-application-using-a-virtual-network-4b03396e9358",
                    }}
                    target="_blank"
                    className="block mb-6"
                  >
                    <figure className="relative h-0 pb-9/16 overflow-hidden rounded-sm">
                      <img
                        className="absolute inset-0 w-full h-full object-cover transform hover:scale-105 transition duration-700 ease-out"
                        src={vpn}
                        width="352"
                        height="198"
                        loading="lazy"
                        alt="News 02"
                      />
                    </figure>
                  </Link>
                  <div className="mb-3">
                    <ul className="flex flex-wrap text-xs font-medium -m-1">
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Networking
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          VPN
                        </Link>
                      </li>
                      <li className="m-1">
                        <Link
                          to="#"
                          className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500"
                        >
                          Security
                        </Link>
                      </li>

                      {/* <li className="m-1">
                        <Link to="#" className="btn block inline-flex text-center text-gray-100 py-1 px-3 rounded-full  border-5 dark:border-teal-500">Page Speed</Link>
                      </li> */}
                    </ul>
                  </div>
                  <h3 className="h4 mb-2">
                    <Link
                      to={{
                        pathname:
                          "https://medium.com/zeza-tech/secure-your-application-using-a-virtual-network-4b03396e9358",
                      }}
                      target="_blank"
                      className="hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Secure Your Application Using A Virtual Network
                    </Link>
                  </h3>
                </header>
                <p className="text-lg text-gray-400 flex-grow">
                  we are going to look at the need of virtual networks, the
                  services provided by different cloud providers such as AWS,
                  GCP and Azure
                </p>
                <footer className="flex items-center mt-4">
                  <Link to="#">
                    <img
                      className="rounded-full flex-shrink-0 mr-4"
                      src={
                        require("../images/teamMembers/rounak-2.2-modified.webp")
                          .default
                      }
                      width="40"
                      height="40"
                      loading="lazy"
                      alt="Author 02"
                    />
                  </Link>
                  <div className="font-medium">
                    <Link
                      to="#"
                      className="text-gray-200 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Rounak Tadvi
                    </Link>
                    <span className="text-gray-700"> - </span>
                    <span className="text-gray-500">Sept 23, 2021</span>
                  </div>
                </footer>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BlogList;
