import React from "react";
import heroImage from "../images/zeza_logo5.png";
import { Link } from "react-router-dom";
function HeroHome() {
  return (
    <section className="min-h-screen">
      {/* <hr /> */}
      <div
        style={{
          display: "grid",
          alignItems: "center",
          // minHeight: '100vh',
          minHeight: "min(47rem, 100vh)",
          scrollSnapAlign: "start",
        }}
        className="pt-10 max-w-6xl  mx-auto px-4 sm:px-6 min-h-screen"
      >
        <div className="">
          <a
            href="https://aiden.engineer"
            target="_blank"
            className="launch_pill"
            rel="noreferrer"
          >
            🎉 Discover Aiden, our freshly launched product! Sign Up Now 🚀
          </a>
          {/* Hero content */}
          <div className="md:grid md:grid-cols-12 md:gap-12 lg:gap-10 items-center ">
            {/* Content */}
            <div className="md:col-span-8 lg:col-span-8 mb-8 md:mb-0 text-center md:text-left">
              <h1
                className="h2 md:h1 mb-7"
                data-aos="fade-down"
                data-aos-delay="150"
              >
                Make data your competitive advantage
              </h1>
              <p
                className="text-xl md:text-2xl text-gray-600 dark:text-gray-400 aos-init aos-animate"
                data-aos="fade-down"
                data-aos-delay="150"
              >
                We build and scale data-intensive robust applications
              </p>

              {/* Lets chat button */}
              <div data-aos="fade-down" data-aos-delay="150">
                <Link
                  to="/contact"
                  className="btn-sm text-center text-regal-blue bg-teal-500 text-xl px-20 transform hover:scale-110 motion-reduce:transform-none mt-16 md:mt-9"
                >
                  Let’s Connect
                </Link>
              </div>
            </div>

            {/* Logo*/}
            <div
              className="md:col-span-4 lg:col-span-4 text-center md:text-right"
              data-aos="fade-down"
              data-aos-delay="150"
            >
              <div className="inline-flex relative justify-center items-center">
                <img
                  className="hidden md:block"
                  src={heroImage}
                  alt="heroImage"
                  style={{ marginTop: "-60px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
