import React from "react";
import apache from "../../../images/Peekinourtoolkit/Artboard 10.png";
import emr from "../../../images/Peekinourtoolkit/Artboard 2.png";
import redshift from "../../../images/Peekinourtoolkit/Artboard 5.png";
import s3 from "../../../images/Peekinourtoolkit/Artboard 6.png";
import debezium from "../../../images/Peekinourtoolkit/Artboard 12.png";
import python from "../../../images/Peekinourtoolkit/Artboard 13.png";
import dbt from "../../../images/Peekinourtoolkit/Artboard 11.png";
import keras from "../../../images/Peekinourtoolkit/Artboard 14.png";
import tensorflow from "../../../images/Peekinourtoolkit/Artboard 8.png";
import glue from "../../../images/Peekinourtoolkit/Artboard 3.png";
import athena from "../../../images/Peekinourtoolkit/Artboard 4.png";
import sagemaker from "../../../images/Peekinourtoolkit/Artboard 15.png";
import Databricks from "../../../images/Peekinourtoolkit/Artboard 1.png";
import pipeline from "../../../images/Peekinourtoolkit/Artboard 7.png";
import codecommit from "../../../images/Peekinourtoolkit/Artboard 9.png";

let toolkit = [
  {
    logo: Databricks,
    name: "Databricks",
    url: "https://databricks.com/",
  },
  {
    logo: emr,
    name: "Amazon EMR",
    url: "https://aws.amazon.com/emr/",
  },
  {
    logo: glue,
    name: "Amazon Glue",
    url: "https://aws.amazon.com/glue/",
  },
  {
    logo: athena,
    name: "Amazon Athena",
    url: "https://aws.amazon.com/athena/",
  },
  {
    logo: redshift,
    name: "Amazon Redshift",
    url: "https://aws.amazon.com/redshift/",
  },
  {
    logo: s3,
    name: "Amazon S3",
    url: "https://aws.amazon.com/s3/",
  },
  {
    logo: pipeline,
    name: "Amazon Datapipeline",
    url: "https://aws.amazon.com/datapipeline/",
  },
  {
    logo: tensorflow,
    name: "Tensorflow",
    url: "https://www.tensorflow.org/",
  },
  {
    logo: codecommit,
    name: "Codecommit",
    url: "https://aws.amazon.com/codecommit/",
  },
  {
    logo: apache,
    name: "Apache",
    url: "https://airflow.apache.org/",
  },
  {
    logo: dbt,
    name: "Dbt",
    url: "https://docs.getdbt.com/",
  },
  {
    logo: debezium,
    name: "Debezium",
    url: "https://debezium.io/",
  },
  {
    logo: python,
    name: "python",
    url: "https://www.python.org/",
  },
  {
    logo: keras,
    name: "keras",
    url: "https://keras.io/",
  },
  {
    logo: sagemaker,
    name: "sagemaker",
    url: "https://aws.amazon.com/sagemaker/",
  },
];

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

/* <div className="grid justify-items-center gap-x-6 gap-y-12 grid-cols-3 ">
      {toolkit.map(({ logo, url, name }) => (
        <div
          key={logo}
          onClick={() => openInNewTab(url)}
          className="w-14 h-14  cursor-pointer"
        >
          <img src={logo} alt="logo" />
          <span className="text-xs text-white font-normal">
            <nobr>{name}</nobr>
          </span>
        </div>
      ))}
    </div> */

function mobiletoolkit1() {
  return (
    <div className="grid justify-items-center gap-10 grid-cols-3 ">
      {toolkit.map(({ logo, url, name }) => (
        <div key={name} className="flex flex-col items-center justify-center">
          <div
            key={logo}
            onClick={() => openInNewTab(url)}
            className="w-14 h-14  cursor-pointer"
          >
            <img src={logo} alt="logo" />
          </div>
          <nobr>
            <span className="text-center capitalize font-semibold pt-2 text-xs">
              {name}
            </span>
          </nobr>
        </div>
      ))}
    </div>
  );
}

export default mobiletoolkit1;
