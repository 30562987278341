import React from "react";

import Cassandra from "../../../images/Peekinourtoolkit/Artboard 32.png";
import flink from "../../../images/Peekinourtoolkit/Artboard 33.png";
import deltalake from "../../../images/Peekinourtoolkit/Artboard 40.png";
import hbase from "../../../images/Peekinourtoolkit/Artboard 34.png";
import postgresql from "../../../images/Peekinourtoolkit/Artboard 35.png";
import dvc from "../../../images/Peekinourtoolkit/Artboard 23.png";
import zgboost from "../../../images/Peekinourtoolkit/Artboard 26.png";
import pytorch from "../../../images/Peekinourtoolkit/Artboard 41.png";
import scikit from "../../../images/Peekinourtoolkit/Artboard 24.png";
import looker from "../../../images/Peekinourtoolkit/Artboard 27.png";
import pycarets from "../../../images/Peekinourtoolkit/Artboard 21.png";
import spacy from "../../../images/Peekinourtoolkit/Artboard 25.png";
import aws from "../../../images/Peekinourtoolkit/Artboard 28.png";
import nginx from "../../../images/Peekinourtoolkit/Artboard 29.png";
import travis from "../../../images/Peekinourtoolkit/Artboard 39.png";
import gitlab from "../../../images/Peekinourtoolkit/Artboard 36.png";
import sumologic from "../../../images/Peekinourtoolkit/Artboard 31.png";
import pyspark from "../../../images/Peekinourtoolkit/Artboard 22.png";
import huggingface from "../../../images/Peekinourtoolkit/Artboard 37.png";
import jenkins from "../../../images/Peekinourtoolkit/Artboard 38.png";

let toolkit1 = [
  { logo: pycarets, url: "https://pycaret.org/", name: "Pycaret" },
  { logo: pyspark, url: "https://spark.apache.org/", name: "Pyspark" },
  {
    logo: dvc,
    name: "dvc",
    url: "https://dvc.org/",
  },
  {
    logo: scikit,
    name: "scikit",
    url: "https://scikit-learn.org/stable/",
  },
  { logo: spacy, name: "spacy", url: "https://spacy.io/" },
  {
    logo: zgboost,
    name: "xgboost",
    url: "https://xgboost.readthedocs.io/en/stable/#",
  },
  {
    logo: looker,
    name: "looker",
    url: "https://looker.com/",
  },
  { logo: aws, name: "aws", url: "https://aws.amazon.com/" },
  { logo: nginx, name: "nginx", url: "https://www.nginx.com/" },
  { logo: sumologic, name: "sumologic", url: "https://www.sumologic.com/" },
  {
    logo: Cassandra,
    name: "Cassandra",
    url: "https://cassandra.apache.org/_/index.html",
  },
  { logo: flink, name: "flink", url: "https://flink.apache.org/" },
  {
    logo: hbase,
    name: "apache hbase",
    url: "https://hbase.apache.org/",
  },
  {
    logo: postgresql,
    name: "postgresql",
    url: "https://www.postgresql.org/",
  },
  { logo: gitlab, name: "gitlab", url: "https://about.gitlab.com/" },
  {
    logo: huggingface,
    name: "huggingface",
    url: "https://huggingface.co/docs/datasets/",
  },
  { logo: jenkins, name: "jenkins", url: "https://www.jenkins.io/" },
  { logo: travis, name: "travis", url: "https://travis-ci.org/" },
  {
    logo: deltalake,
    name: "deltalake",
    url: "https://delta.io/",
  },
  {
    logo: pytorch,
    name: "pytorch",
    url: "https://pytorch.org/",
  },
];

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

function peekinourtoolkit2() {
  return (
    <div className="grid justify-items-center gap-x-8 gap-y-16 grid-cols-5">
      {toolkit1.map(({ logo, url, name }) => (
        <div
          onClick={() => openInNewTab(url)}
          key={url}
          className="w-14 h-14 cursor-pointer"
        >
          <div className="flex flex-col items-center justify-center group">
            <img
              src={logo}
              alt="logo"
              className="transform hover:scale-110 motion-reduce:transform-none"
            />
            <span className=" pt-1 capitalize opacity-100 text-sm font-semibold text-gray-400 dark:text-gray-400">
              <nobr>{name}</nobr>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default peekinourtoolkit2;
