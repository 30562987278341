import React from 'react'

export default function SectionDivider() {
    return (
        <hr
        style={{
            borderColor: 'gray',
            boxShadow: '0 0 5px 1px #80808038',
            margin: '0 auto',
            opacity: 0

        }}
        className= "max-w-5xl"
        />
    )
}
