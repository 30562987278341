import { React } from "react";
import Mailchimp from "react-mailchimp-form";

function Cta() {
  return (
    // <section>
    //   <div className="max-w-6xl mx-auto px-4 sm:px-6">
    //     {/* CTA box */}
    //     <div className="dark relative bg-gray-800 mb-8 py-10 px-8 md:py-10 md:px-12">
    //       {/* Background illustration */}
    //       <div
    //         className="absolute inset-0 left-auto  pointer-events-none"
    //         aria-hidden="true"
    //       >
    //         <svg
    //           className="h-full"
    //           width="400"
    //           height="232"
    //           viewBox="0 0 400 232"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <defs>
    //             <radialGradient
    //               cx="50%"
    //               cy="50%"
    //               fx="50%"
    //               fy="50%"
    //               r="39.386%"
    //               id="box-gr-a"
    //             >
    //               <stop stopColor="#667EEA" offset="0%" />
    //               <stop stopColor="#667EEA" stopOpacity="0" offset="100%" />
    //             </radialGradient>
    //             <radialGradient
    //               cx="50%"
    //               cy="50%"
    //               fx="50%"
    //               fy="50%"
    //               r="39.386%"
    //               id="box-gr-b"
    //             >
    //               <stop stopColor="#3ABAB4" offset="0%" />
    //               <stop stopColor="#3ABAB4" stopOpacity="0" offset="100%" />
    //             </radialGradient>
    //           </defs>
    //           <g transform="translate(-85 -369)" fill="none" fillRule="evenodd">
    //             <circle
    //               fillOpacity=".16"
    //               fill="url(#box-gr-a)"
    //               cx="413"
    //               cy="688"
    //               r="240"
    //             />
    //             <circle
    //               fillOpacity=".24"
    //               fill="url(#box-gr-b)"
    //               cx="400"
    //               cy="400"
    //               r="400"
    //             />
    //           </g>
    //         </svg>
    //       </div>

    //       <div className="relative  mx-auto flex flex-row items-center justify-between">
    //         {/* CTA header */}
    //         <div className="w-5/12">
    //           <h3 className="text-2xl font-extrabold text-white mb-2">
    //             Get Machine learning, Data and Software insights.
    //           </h3>
    //           <p className="text-gray-400 text-xl  mt-4">
    //             We promise not to spam you!
    //           </p>
    //         </div>
    //         <div className="w-3/12"></div>
    //         <div className="w-4/12">
    //           <Mailchimp
    //             action="https://tech.us5.list-manage.com/subscribe/post?u=0f6e4763041d9680190937ffc&amp;id=b7d40360dd"
    //             fields={[
    //               {
    //                 name: "EMAIL",
    //                 placeholder: "Email",
    //                 type: "email",
    //                 required: true,
    //                 class: "bg-black",
    //               },
    //             ]}
    //             className="flex flex-row text-center"
    //           />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   {/* <div className="mt-2">
    //   <SectionDivider />
    // </div> */}
    // </section>
    <section className="pb-7">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        {/* CTA box */}
        <div className="dark relative bg-gray-800 py-10 px-6 md:py-16 md:px-12">
          {/* Background illustration */}
          <div
            className="absolute inset-0 left-auto  pointer-events-none"
            aria-hidden="true"
          >
            <svg
              className="h-full"
              width="400"
              height="232"
              viewBox="0 0 400 232"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <radialGradient
                  cx="50%"
                  cy="50%"
                  fx="50%"
                  fy="50%"
                  r="39.386%"
                  id="box-gr-a"
                >
                  <stop stopColor="#667EEA" offset="0%" />
                  <stop stopColor="#667EEA" stopOpacity="0" offset="100%" />
                </radialGradient>
                <radialGradient
                  cx="50%"
                  cy="50%"
                  fx="50%"
                  fy="50%"
                  r="39.386%"
                  id="box-gr-b"
                >
                  <stop stopColor="#3ABAB4" offset="0%" />
                  <stop stopColor="#3ABAB4" stopOpacity="0" offset="100%" />
                </radialGradient>
              </defs>
              <g transform="translate(-85 -369)" fill="none" fillRule="evenodd">
                <circle
                  fillOpacity=".16"
                  fill="url(#box-gr-a)"
                  cx="413"
                  cy="688"
                  r="240"
                />
                <circle
                  fillOpacity=".24"
                  fill="url(#box-gr-b)"
                  cx="400"
                  cy="400"
                  r="400"
                />
              </g>
            </svg>
          </div>

          <div className="relative flex flex-col lg:flex-row justify-between items-center sm:mx-12 sm:px-2 gap-x-8">
            {/* CTA content */}
            <div className="flex flex-col">
              <div className="text-2xl font-extrabold text-white mb-2 md:mb-0 text-center md:text-left">
                Get Machine learning, <br /> Data and Software Insights.
              </div>
              <span className="text-gray-400 text-xl text-center md:text-left mt-4 md:mt-0">
                We promise not to spam you.
              </span>
            </div>
            {/* CTA form */}
            {/* <form className="w-full lg:w-1/2"> */}
            {/* <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none"> */}

            <div className="flex flex-col">
              <Mailchimp
                action="https://tech.us5.list-manage.com/subscribe/post?u=0f6e4763041d9680190937ffc&amp;id=b7d40360dd"
                fields={[
                  {
                    name: "EMAIL",
                    placeholder: "Email",
                    type: "email",
                    required: true,
                  },
                ]}
                className=" sm:mb-0 sm:ml-8  text-center relative flex flex-col lg:flex-row justify-between items-center"
              />
            </div>
            {/* </div> */}
            {/* <form className="w-full lg:w-1/2" action="https://tech.us5.list-manage.com/subscribe/post?u=0f6e4763041d9680190937ffc&amp;id=b7d40360dd" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>

              <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none"> */}
            {/* <input type="email" name="EMAIL" id="mce-EMAIL" className="form-input w-full mb-2 sm:mb-0 sm:mr-2" placeholder="Email" aria-label="Email" /> */}
            {/* <a className="btn text-white bg-teal-500 hover:bg-teal-400 flex-shrink-0" href="#0">Subscribe</a> */}
            {/* <input className="btn text-white bg-teal-500 hover:bg-teal-400 flex-shrink-0" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></input> */}
            {/* </div> */}
            {/* Success message */}
            {/* <p className="text-center lg:text-left lg:absolute mt-2 opacity-75 text-sm">Thanks for subscribing!</p> */}
            {/* </form> */}
            {/* <form className="w-full lg:w-1/2" action="https://tech.us5.list-manage.com/subscribe/post?u=0f6e4763041d9680190937ffc&amp;id=b7d40360dd" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate> */}

            {/* <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:max-w-none"> */}
            {/* <input type="email" name="EMAIL" id="mce-EMAIL" className="form-input w-full mb-2 sm:mb-0 sm:mr-2" placeholder="Email" aria-label="Email" /> */}
            {/* <a className="btn text-white bg-teal-500 hover:bg-teal-400 flex-shrink-0" href="#0">Subscribe</a> */}
            {/* <input className="btn text-white bg-teal-500 hover:bg-teal-400 flex-shrink-0" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"></input> */}
            {/* </div> */}
            {/* Success message */}
            {/* <p className="text-center lg:text-left lg:absolute mt-2 opacity-75 text-sm">Thanks for subscribing!</p> */}
            {/* </form> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
