import React from "react";
import elestisearchh from "../../../images/Peekinourtoolkit/Artboard 51.png";
import snowflake from "../../../images/Peekinourtoolkit/Artboard 52.png";

import matplotlib from "../../../images/Peekinourtoolkit/Artboard 53.png";
import jupyter from "../../../images/Peekinourtoolkit/Artboard 55.png";

import anaconda from "../../../images/Peekinourtoolkit/Artboard 54.png";

import seabornn from "../../../images/Peekinourtoolkit/Artboard 56.png";
import Circleci from "../../../images/Peekinourtoolkit/Artboard 59.png";

import Tableau from "../../../images/Peekinourtoolkit/Artboard 58.png";
import kubernates from "../../../images/Peekinourtoolkit/Artboard 60.png";
import teeraform from "../../../images/Peekinourtoolkit/Artboard 50.png";
import azure from "../../../images/Peekinourtoolkit/Artboard 47.png";
import atlantis from "../../../images/Peekinourtoolkit/Artboard 61.png";

import istio from "../../../images/Peekinourtoolkit/Artboard 48.png";
import vault from "../../../images/Peekinourtoolkit/Artboard 49.png";
import periscope from "../../../images/Peekinourtoolkit/Artboard 57.png";

let toolkit3 = [
  { logo: azure, name: "azure", url: "https://azure.microsoft.com/en-in/" },
  { logo: istio, name: "istio", url: "https://istio.io/" },
  { logo: vault, name: "vault", url: "https://www.vaultproject.io/" },
  { logo: teeraform, name: "Terraform", url: "https://www.terraform.io/" },
  {
    logo: elestisearchh,
    name: "elasticsearch",
    url: "https://www.elastic.co/",
  },
  {
    logo: snowflake,
    name: "snowflake",
    url: "https://www.snowflake.com/",
  },
  {
    logo: matplotlib,
    name: "matplotlib",
    url: "https://matplotlib.org",
  },
  {
    logo: anaconda,
    name: "anaconda",
    url: "https://www.anaconda.com/",
  },
  {
    logo: jupyter,
    name: "jupyter",
    url: "https://jupyter.org/",
  },
  {
    logo: seabornn,
    name: "seaborn",
    url: "https://seaborn.pydata.org/",
  },
  { logo: periscope, name: "periscope", url: "https://www.pscp.tv/" },
  { logo: Tableau, name: "Tableau", url: "https://www.tableau.com/" },
  {
    logo: Circleci,
    name: "Circleci",
    url: "https://circleci.com/",
  },
  { logo: kubernates, name: "kubernetes", url: "https://kubernetes.io/" },
  { logo: atlantis, name: "atlantis", url: "https://www.runatlantis.io/" },
];

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

function mobiletoolkit4() {
  return (
    <div className="grid justify-items-center gap-10 grid-cols-3 ">
      {toolkit3.map(({ logo, url, name }) => (
        <div className="flex flex-col items-center justify-center">
          <div
            key={logo}
            onClick={() => openInNewTab(url)}
            className="w-14 h-14  cursor-pointer"
          >
            <img src={logo} alt="logo" />
          </div>
          <nobr>
            <span className="text-center capitalize font-semibold pt-2 text-xs">
              {name}
            </span>
          </nobr>
        </div>
      ))}
    </div>
  );
}

export default mobiletoolkit4;
