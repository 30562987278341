import React from "react";
import elestisearchh from "../../../images/Peekinourtoolkit/Artboard 51.png";
import snowflake from "../../../images/Peekinourtoolkit/Artboard 52.png";
import weka from "../../../images/Peekinourtoolkit/Artboard 42.png";
import matplotlib from "../../../images/Peekinourtoolkit/Artboard 53.png";
import jupyter from "../../../images/Peekinourtoolkit/Artboard 55.png";
import metabase from "../../../images/Peekinourtoolkit/Artboard 43.png";
import anaconda from "../../../images/Peekinourtoolkit/Artboard 54.png";
import superset from "../../../images/Peekinourtoolkit/Artboard 45.png";
import seabornn from "../../../images/Peekinourtoolkit/Artboard 56.png";
import Circleci from "../../../images/Peekinourtoolkit/Artboard 59.png";
import redash from "../../../images/Peekinourtoolkit/Artboard 44.png";
import Tableau from "../../../images/Peekinourtoolkit/Artboard 58.png";
import kubernates from "../../../images/Peekinourtoolkit/Artboard 60.png";
import teeraform from "../../../images/Peekinourtoolkit/Artboard 50.png";
import azure from "../../../images/Peekinourtoolkit/Artboard 47.png";
import atlantis from "../../../images/Peekinourtoolkit/Artboard 61.png";
import googlecloud from "../../../images/Peekinourtoolkit/Artboard 46.png";
import istio from "../../../images/Peekinourtoolkit/Artboard 48.png";
import vault from "../../../images/Peekinourtoolkit/Artboard 49.png";
import periscope from "../../../images/Peekinourtoolkit/Artboard 57.png";

let toolkit3 = [
  {
    logo: weka,
    name: "weka",
    url: "https://www.weka.io/",
  },
  {
    logo: metabase,
    name: "metabase",
    url: "https://www.metabase.com/",
  },
  {
    logo: redash,
    name: "redash",
    url: "https://redash.io/",
  },
  {
    logo: superset,
    name: "superset",
    url: "https://superset.apache.org/",
  },
  { logo: googlecloud, name: "google cloud", url: "https://cloud.google.com/" },
  { logo: azure, name: "azure", url: "https://azure.microsoft.com/en-in/" },
  { logo: istio, name: "istio", url: "https://istio.io/" },
  { logo: vault, name: "vault", url: "https://www.vaultproject.io/" },
  { logo: teeraform, name: "terraform", url: "https://www.terraform.io/" },
  {
    logo: elestisearchh,
    name: "elasticsearch",
    url: "https://www.elastic.co/",
  },
  {
    logo: snowflake,
    name: "snowflake",
    url: "https://www.snowflake.com/",
  },
  {
    logo: matplotlib,
    name: "matplotlib",
    url: "https://matplotlib.org",
  },
  {
    logo: anaconda,
    name: "anaconda",
    url: "https://www.anaconda.com/",
  },
  {
    logo: jupyter,
    name: "jupyter",
    url: "https://jupyter.org/",
  },
  {
    logo: seabornn,
    name: "seaborn",
    url: "https://seaborn.pydata.org/",
  },
  { logo: periscope, name: "periscope", url: "https://www.pscp.tv/" },
  { logo: Tableau, name: "Tableau", url: "https://www.tableau.com/" },
  {
    logo: Circleci,
    name: "Circleci",
    url: "https://circleci.com/",
  },
  { logo: kubernates, name: "kubernetes", url: "https://kubernetes.io/" },
  { logo: atlantis, name: "atlantis", url: "https://www.runatlantis.io/" },
];

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

function peekinourtoolkit3() {
  return (
    <div className="grid justify-items-center gap-x-8 gap-y-16 grid-cols-5">
      {toolkit3.map(({ logo, url, name }) => (
        <div
          onClick={() => openInNewTab(url)}
          key={url}
          className="w-14 h-14 cursor-pointer"
        >
          <div className="flex flex-col items-center justify-center group">
            <img
              src={logo}
              alt="logo"
              className="transform hover:scale-110 motion-reduce:transform-none"
            />
            <span className=" pt-1 capitalize opacity-100 text-sm font-semibold text-gray-400 dark:text-gray-400">
              <nobr>{name}</nobr>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default peekinourtoolkit3;
