import React from "react";
import { useState } from "react";
import Peekinourtoolkit1 from "./peekinourtoolkit/desktop/Peekinourtoolkit1";
import Peekinourtoolkit2 from "./peekinourtoolkit/desktop/Peekinouttoolkit2";
import Peekinourtoolkit3 from "./peekinourtoolkit/desktop/Peekinourtoolkit3";
import Peekinourtoolkit4 from "./peekinourtoolkit/desktop/Peekinourtoolkit4";
import Mobiletoolkit1 from "./peekinourtoolkit/mobile/Mobiletoolkit1";
import Mobiletoolkit2 from "./peekinourtoolkit/mobile/Mobiletoolkit2";
import Mobiletoolkit3 from "./peekinourtoolkit/mobile/Mobiletoolkit3";
import Mobiletoolkit4 from "./peekinourtoolkit/mobile/Mobiletoolkit4";
import Mobiletoolkit5 from "./peekinourtoolkit/mobile/Mobiletoolkit5";

function OurToolkit() {
  // const [selected, setSelected] = useState("Data Engineering");
  const [pagecount, setpagecount] = useState(0);
  const [mobilecount, setmobilepagecount] = useState(0);
  let handlerightchange = () => {
    setpagecount((pagecount) => pagecount + 1);
  };
  let handlemobileright = () => {
    setmobilepagecount((mobilecount) => mobilecount + 1);
  };
  let handleleftchange = () => {
    setpagecount((pagecount) => pagecount - 1);
  };
  let handlemobileleft = () => {
    setmobilepagecount((mobilecount) => mobilecount - 1);
  };
  return (
    <section>
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 ">
          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 text-3xl md:text-4xl  mb-4 md:mb-8">
              Peek in our toolkit
            </h1>
            <p className="text-lg md:text-xl  font-medium text-gray-400 dark:text-gray-400">
              Tools play an important role in any organization, we choose them
              wisely.
            </p>
          </div>

          {/* mobile */}
          <div className="md:hidden flex flex-row items-center">
            <div
              onClick={() => {
                if (mobilecount > 0) {
                  handlemobileleft();
                } else {
                  setmobilepagecount(4);
                }
              }}
              className="cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 19l-7-7 7-7"
                />
              </svg>
            </div>
            <div className="w-full px-2 select-none">
              {parseInt(mobilecount % 5) === 0 ? <Mobiletoolkit1 /> : ""}
              {parseInt(mobilecount % 5) === 1 ? <Mobiletoolkit2 /> : ""}
              {parseInt(mobilecount % 5) === 2 ? <Mobiletoolkit3 /> : ""}
              {parseInt(mobilecount % 5) === 3 ? <Mobiletoolkit4 /> : ""}
              {parseInt(mobilecount % 5) === 4 ? <Mobiletoolkit5 /> : ""}
            </div>
            <div onClick={() => handlemobileright()} className="cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </div>
          </div>

          {/* desktop */}
          <div className="hidden md:flex flex-row items-center">
            <div
              onClick={() => {
                if (pagecount > 0) {
                  handleleftchange();
                } else {
                  setpagecount(3);
                }
              }}
              className="cursor-pointer"
            >
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 19l-7-7 7-7"
                />
              </svg> */}
              <div className="carousel-prev relative z-20 w-12 h-12 p-1 box-content flex items-center justify-center group bg-teal-500 hover:bg-teal-400 dark:bg-gray-800 dark:hover:bg-teal-500 dark:hover:bg-opacity-25 shadow-xl transition duration-150 ease-in-out">
                <span className="sr-only">Previous</span>
                <svg
                  className="w-4 h-4 fill-current text-white dark:text-gray-400 group-hover:text-white dark:group-hover:text-teal-500 transition duration-150 ease-in-out"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
                </svg>
              </div>
            </div>
            <div className="w-full px-2 select-none">
              {parseInt(pagecount % 4) === 0 ? <Peekinourtoolkit1 /> : ""}
              {parseInt(pagecount % 4) === 1 ? <Peekinourtoolkit2 /> : ""}
              {parseInt(pagecount % 4) === 2 ? <Peekinourtoolkit3 /> : ""}
              {parseInt(pagecount % 4) === 3 ? <Peekinourtoolkit4 /> : ""}
            </div>
            <div
              onClick={() => handlerightchange()}
              className=" cursor-pointer flex items-end justify-end"
            >
              <div className="carousel-next relative z-20 w-12 h-12 p-1 box-content flex items-center justify-center group bg-teal-500 hover:bg-teal-400 dark:bg-gray-800 dark:hover:bg-teal-500 dark:hover:bg-opacity-25 shadow-xl transition duration-150 ease-in-out">
                <span className="sr-only">Next</span>
                <svg
                  className="w-4 h-4 fill-current text-white dark:text-gray-400 group-hover:text-white dark:group-hover:text-teal-500 transition duration-150 ease-in-out"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                </svg>
              </div>
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-10 w-10"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg> */}
            </div>
          </div>
          {/* Custom animation */}
          {/* <div className="flex  w-full items-center justify-between">
            <div className="flex flex-col space-y-10 w-2/12">
              <button
                onClick={() => setSelected("Data Engineering")}
                className={`px-2 py-1.5 w-44 h-20 rounded-lg`}
              >
                <span
                  className={`text-gray-300 font-semibold text-xl  ${
                    selected === "Data Engineering"
                      ? " text-teal-400"
                      : "text-white"
                  }`}
                >
                  <nobr> Data Engineering</nobr>
                </span>
              </button>
              <button
                onClick={() => setSelected("Business Intelligence")}
                className={`px-2 py-1.5 w-44 h-20 rounded-lg`}
              >
                <span
                  className={`text-gray-300 font-semibold text-xl ${
                    selected === "Business Intelligence"
                      ? " text-teal-400"
                      : "text-white"
                  }`}
                >
                  Business Intelligence
                </span>
              </button>
              <button
                onClick={() => setSelected("Machine Learning")}
                className={`px-2 py-1.5 w-44 h-20 rounded-lg`}
              >
                <span
                  className={`text-gray-300 font-semibold text-xl  ${
                    selected === "Machine Learning"
                      ? " text-teal-400"
                      : "text-white"
                  }`}
                >
                  Machine Learning
                </span>
              </button>
              <button
                onClick={() => setSelected("Infrastructure")}
                className={`px-2 py-1.5 w-44 h-20 rounded-lg`}
              >
                <span
                  className={`text-gray-300 font-semibold text-xl  ${
                    selected === "Infrastructure"
                      ? " text-teal-400"
                      : "text-white"
                  }`}
                >
                  Infrastructure
                </span>
              </button>
            </div>
            <div className="w-9/12">
              {selected === "Infrastructure" ? <infrastructure /> : ""}
              {selected === "Machine Learning" ? <machinelearning /> : ""}
              {selected === " Business Intelligence" ? (
                <businessintelligence />
              ) : (
                ""
              )}
              {selected === "Data Engineering" ? <dataengineering /> : ""}
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default OurToolkit;
