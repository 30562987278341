import React from "react";
// import clientLogo1 from "../images/client-logo-1.png";
// import clientLogo2 from "../images/client_logo_2.png";
// import clientLogo3 from "../images/client_logo_3.png";
import settings from "../css/tailwind.config";

// let data = [
//   {
//     quote:
//       "“ The team is super-prompt on communication. The presentation and updates are thorough, and their passion for technology is noticeable. I would vouch for their attention to detail and strong technical expertise, especially for Machine Learning. “",
//     name: "Siddharth Malhotra",
//     role: "Co-CEO at",
//     team: "Xcellen ",
//     link: "#0",
//     image: clientLogo3,
//     style: {
//       gridColumn: "5/9",
//     },
//   },
//   {
//     quote: `“ Zeza exceeded our expectations for the project delivery and went above and beyond the project scope to provide good support. They are knowledgeable and have a tremendous 'can-do attitude. In addition, they are attentive, personable, and professional. “`,
//     name: "Girish Dwivedi",
//     role: "Director at",
//     team: "Accede LLC",
//     link: "#0",
//     image: clientLogo2,
//     style: {
//       gridColumn: "1/5",
//       gridRow: "1/2",
//     },
//   },
//   {
//     quote:
//       "“ The engineering team at Zeza was able to convert a rough idea into an MVP product under tight deadlines. The team was quick, helpful, and courteous. Their biweekly sprint reviews and agile project management made them good software engineering partners for building our product. “",
//     name: "S. Hari",
//     role: "Director at",
//     team: "Seosaph Embedded Solutions",
//     link: "#0",
//     image: clientLogo1,
//     style: {},
//   },
// ];
function TestimonialCards() {
  let colors = settings.theme.extend.colors;
  return (
    <section
      style={{
        minHeight: "min(47rem, 100vh)",
        display: "grid",
        placeContent: "center",
        scrollSnapAlign: "start",
      }}
      className="min-h-60 md:min-h-43 mt-10"
    >
      <div className="max-w-6xl mx-auto py-12 px-4 sm:px-6">
        <div className="max-w-3xl mx-auto text-center pb-12 ">
          <h1 className="h2 text-3xl md:text-4xl mb-4 md:mb-8 ">
            Don't take our word for it
          </h1>
          <p className="text-lg md:text-xl font-medium text-gray-400 dark:text-gray-400 mb-4">
            Here's what our clients have to say about us.
          </p>
        </div>

        <div className="md:grid md:grid-cols-12 md:gap-8">
          <div
            style={{ background: "#2b2f40", gridColumn: "1/5", gridRow: "1/2" }}
            className="md:flex md:flex-col mb-5 col-span-4 p-5 pt-12 "
          >
            <svg
              className="mb-4"
              width="24"
              height="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 13.481c0-2.34.611-4.761 1.833-7.263C3.056 3.716 4.733 1.643 6.865 0L11 2.689C9.726 4.382 8.777 6.093 8.152 7.824c-.624 1.73-.936 3.578-.936 5.545V18H0v-4.519zm13 0c0-2.34.611-4.761 1.833-7.263 1.223-2.502 2.9-4.575 5.032-6.218L24 2.689c-1.274 1.693-2.223 3.404-2.848 5.135-.624 1.73-.936 3.578-.936 5.545V18H13v-4.519z"
                fill={colors.teal[500]}
                fillRule="nonzero"
              />
            </svg>
            <div className="space-y-3 mb-10">
              <p className="dark:text-gray-400 text-lg leading-7">
                “ The team is super-prompt on communication.
              </p>
              <p className="dark:text-gray-400 text-lg leading-7">
                The presentation and updates are thorough, and their passion for
                technology is noticeable.
              </p>
              <p className="dark:text-gray-400 text-lg leading-7">
                I would vouch for their attention to detail and strong technical
                expertise, especially for Machine Learning “
              </p>
            </div>

            <p style={{ marginTop: "auto" }}>
              <strong className="h5">Siddharth Malhotra</strong>
              <span className="dark:text-gray-400"> / </span>
              <span className="text-teal-500">Xcellen</span>
            </p>
          </div>
          <div
            style={{ background: "#2b2f40", gridColumn: "5/9" }}
            className="md:flex md:flex-col mb-5 col-span-4 p-5 pt-12 "
          >
            <svg
              className="mb-4"
              width="24"
              height="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 13.481c0-2.34.611-4.761 1.833-7.263C3.056 3.716 4.733 1.643 6.865 0L11 2.689C9.726 4.382 8.777 6.093 8.152 7.824c-.624 1.73-.936 3.578-.936 5.545V18H0v-4.519zm13 0c0-2.34.611-4.761 1.833-7.263 1.223-2.502 2.9-4.575 5.032-6.218L24 2.689c-1.274 1.693-2.223 3.404-2.848 5.135-.624 1.73-.936 3.578-.936 5.545V18H13v-4.519z"
                fill={colors.teal[500]}
                fillRule="nonzero"
              />
            </svg>
            <div className="space-y-3 mb-10">
              <p className="dark:text-gray-400 text-lg leading-7 ">
                “ Zeza exceeded our expectations for the project delivery and
                went above and beyond the project scope to provide good support.
              </p>
              <p className="dark:text-gray-400 text-lg leading-7 ">
                They are knowledgeable and have a tremendous 'can-do attitude.
              </p>
              <p className="dark:text-gray-400 text-lg leading-7 ">
                In addition, they are attentive, personable, and professional. “
              </p>
            </div>

            <p style={{ marginTop: "auto" }}>
              <strong className="h5">Girish Dwivedi</strong>
              <span className="dark:text-gray-400"> / </span>
              <span className="text-teal-500">Accede LLC</span>
            </p>
          </div>
          <div
            style={{ background: "#2b2f40" }}
            className="md:flex md:flex-col mb-5 col-span-4 p-5 pt-12 "
          >
            <svg
              className="mb-4"
              width="24"
              height="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 13.481c0-2.34.611-4.761 1.833-7.263C3.056 3.716 4.733 1.643 6.865 0L11 2.689C9.726 4.382 8.777 6.093 8.152 7.824c-.624 1.73-.936 3.578-.936 5.545V18H0v-4.519zm13 0c0-2.34.611-4.761 1.833-7.263 1.223-2.502 2.9-4.575 5.032-6.218L24 2.689c-1.274 1.693-2.223 3.404-2.848 5.135-.624 1.73-.936 3.578-.936 5.545V18H13v-4.519z"
                fill={colors.teal[500]}
                fillRule="nonzero"
              />
            </svg>
            <div className="space-y-3 mb-10">
              <p className="dark:text-gray-400 text-lg leading-7">
                “ The engineering team at Zeza was able to convert a rough idea
                into an MVP product under tight deadlines.
              </p>
              <p className="dark:text-gray-400 text-lg leading-7">
                The team was quick, helpful, and courteous.
              </p>
              <p className="dark:text-gray-400 text-lg leading-7">
                Their biweekly sprint reviews and agile project management made
                them good software engineering partners for building our
                product.“
              </p>
            </div>
            <p style={{ marginTop: "auto" }}>
              <strong className="h5">S. Hari</strong>
              <span className="dark:text-gray-400"> / </span>
              <span className="text-teal-500">Seosaph Embedded Solutions</span>
            </p>
          </div>
        </div>
      </div>
      <div
        className=""
        style={{
          height: "50%",
          width: "100%",
          bottom: 0,
          scrollSnapAlign: "end",
        }}
      ></div>
    </section>
  );
}

export default TestimonialCards;
