import React from "react";
import Cassandra from "../../../images/Peekinourtoolkit/Artboard 32.png";
import flink from "../../../images/Peekinourtoolkit/Artboard 33.png";
import deltalake from "../../../images/Peekinourtoolkit/Artboard 40.png";
import hbase from "../../../images/Peekinourtoolkit/Artboard 34.png";
import postgresql from "../../../images/Peekinourtoolkit/Artboard 35.png";
import pytorch from "../../../images/Peekinourtoolkit/Artboard 41.png";
import travis from "../../../images/Peekinourtoolkit/Artboard 39.png";
import gitlab from "../../../images/Peekinourtoolkit/Artboard 36.png";
import huggingface from "../../../images/Peekinourtoolkit/Artboard 37.png";
import jenkins from "../../../images/Peekinourtoolkit/Artboard 38.png";
import googlecloud from "../../../images/Peekinourtoolkit/Artboard 46.png";
import weka from "../../../images/Peekinourtoolkit/Artboard 42.png";
import redash from "../../../images/Peekinourtoolkit/Artboard 44.png";
import metabase from "../../../images/Peekinourtoolkit/Artboard 43.png";
import superset from "../../../images/Peekinourtoolkit/Artboard 45.png";

let toolkit3 = [
  {
    logo: Cassandra,
    name: "Cassandra",
    url: "https://cassandra.apache.org/_/index.html",
  },
  { logo: flink, name: "flink", url: "https://flink.apache.org/" },
  {
    logo: hbase,
    name: "hbase",
    url: "https://hbase.apache.org/",
  },
  {
    logo: postgresql,
    name: "postgresql",
    url: "https://www.postgresql.org/",
  },
  { logo: gitlab, name: "gitlab", url: "https://about.gitlab.com/" },
  {
    logo: huggingface,
    name: "huggingface",
    url: "https://huggingface.co/docs/datasets/",
  },
  { logo: jenkins, name: "jenkins", url: "https://www.jenkins.io/" },
  { logo: travis, name: "travis", url: "https://travis-ci.org/" },
  {
    logo: deltalake,
    name: "deltalake",
    url: "https://delta.io/",
  },
  {
    logo: pytorch,
    name: "pytorch",
    url: "https://pytorch.org/",
  },
  {
    logo: weka,
    name: "weka",
    url: "https://www.weka.io/",
  },
  {
    logo: metabase,
    name: "metabase",
    url: "https://www.metabase.com/",
  },
  {
    logo: redash,
    name: "redash",
    url: "https://redash.io/",
  },
  {
    logo: superset,
    name: "superset",
    url: "https://superset.apache.org/",
  },
  { logo: googlecloud, name: "googlecloud", url: "https://cloud.google.com/" },
];

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

function mobiletoolkit3() {
  return (
    <div className="grid justify-items-center gap-10 grid-cols-3 ">
      {toolkit3.map(({ logo, url, name }) => (
        <div className="flex flex-col items-center justify-center">
          <div
            key={logo}
            onClick={() => openInNewTab(url)}
            className="w-14 h-14  cursor-pointer"
          >
            <img src={logo} alt="logo" />
          </div>
          <nobr>
            <span className="text-center capitalize font-semibold pt-2 text-xs">
              {name}
            </span>
          </nobr>
        </div>
      ))}
    </div>
  );
}

export default mobiletoolkit3;
