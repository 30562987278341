import React from "react";
import apache from "../../../images/Peekinourtoolkit/Artboard 10.png";
import emr from "../../../images/Peekinourtoolkit/Artboard 2.png";
import redshift from "../../../images/Peekinourtoolkit/Artboard 5.png";
import s3 from "../../../images/Peekinourtoolkit/Artboard 6.png";
import debezium from "../../../images/Peekinourtoolkit/Artboard 12.png";
import python from "../../../images/Peekinourtoolkit/Artboard 13.png";
import dbt from "../../../images/Peekinourtoolkit/Artboard 11.png";
import keras from "../../../images/Peekinourtoolkit/Artboard 14.png";
import tensorflow from "../../../images/Peekinourtoolkit/Artboard 8.png";
import glue from "../../../images/Peekinourtoolkit/Artboard 3.png";
import athena from "../../../images/Peekinourtoolkit/Artboard 4.png";
import Sqoop from "../../../images/Peekinourtoolkit/Artboard 19.png";
import sagemaker from "../../../images/Peekinourtoolkit/Artboard 15.png";
import quicksight from "../../../images/Peekinourtoolkit/Artboard 16.png";
import mysql from "../../../images/Peekinourtoolkit/Artboard 20.png";
import Databricks from "../../../images/Peekinourtoolkit/Artboard 1.png";
import pipeline from "../../../images/Peekinourtoolkit/Artboard 7.png";
import logstash from "../../../images/Peekinourtoolkit/Artboard 17.png";
import codecommit from "../../../images/Peekinourtoolkit/Artboard 9.png";
import kibana from "../../../images/Peekinourtoolkit/Artboard 66.png";

let toolkit = [
  {
    logo: Databricks,
    name: "Databricks",
    url: "https://databricks.com/",
  },
  {
    logo: emr,
    name: "Amazon EMR",
    url: "https://aws.amazon.com/emr/",
  },
  {
    logo: glue,
    name: "Amazon Glue",
    url: "https://aws.amazon.com/glue/",
  },
  {
    logo: athena,
    name: "Amazon Athena",
    url: "https://aws.amazon.com/athena/",
  },
  {
    logo: redshift,
    name: "Amazon Redshift",
    url: "https://aws.amazon.com/redshift/",
  },
  {
    logo: s3,
    name: "Amazon S3",
    url: "https://aws.amazon.com/s3/",
  },
  {
    logo: pipeline,
    name: "Amazon Datapipeline",
    url: "https://aws.amazon.com/datapipeline/",
  },
  {
    logo: tensorflow,
    name: "Tensorflow",
    url: "https://www.tensorflow.org/",
  },
  {
    logo: codecommit,
    name: "Codecommit",
    url: "https://aws.amazon.com/codecommit/",
  },
  {
    logo: apache,
    name: "Apache",
    url: "https://airflow.apache.org/",
  },
  {
    logo: dbt,
    name: "Dbt",
    url: "https://docs.getdbt.com/",
  },
  {
    logo: debezium,
    name: "Debezium",
    url: "https://debezium.io/",
  },
  {
    logo: python,
    name: "python",
    url: "https://www.python.org/",
  },
  {
    logo: keras,
    name: "keras",
    url: "https://keras.io/",
  },
  {
    logo: sagemaker,
    name: "sagemaker",
    url: "https://aws.amazon.com/sagemaker/",
  },
  {
    logo: quicksight,
    name: "quicksight",
    url: "https://aws.amazon.com/quicksight/",
  },
  {
    logo: logstash,
    name: "logstash",
    url: "https://www.elastic.co/logstash/",
  },
  {
    logo: kibana,
    name: "kibana",
    url: "https://www.elastic.co/kibana/",
  },
  {
    logo: Sqoop,
    name: "Sqoop",
    url: "https://sqoop.apache.org/",
  },
  {
    logo: mysql,
    name: "mysql",
    url: "https://www.mysql.com/",
  },
];

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

function peekinourtoolkit1() {
  return (
    <div className="grid justify-items-center gap-x-8 gap-y-16 grid-cols-5 ">
      {toolkit.map(({ logo, url, name }) => (
        <div
          onClick={() => openInNewTab(url)}
          key={url}
          className="w-14 h-14 cursor-pointer"
        >
          <div className="flex flex-col items-center justify-center group">
            <img
              src={logo}
              alt="logo"
              className="transform hover:scale-110 motion-reduce:transform-none"
            />
            <span className=" pt-1 capitalize opacity-100 text-sm font-semibold text-gray-400 dark:text-gray-400">
              <nobr>{name}</nobr>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default peekinourtoolkit1;
