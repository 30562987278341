import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../partials/Header";
import PageIllustration from "../partials/PageIllustration";
import Footer from "../partials/Footer";
import Dataengineering from "../partials/ourexpertise/dataengineering";
import Infrastructure from "../partials/ourexpertise/Infrastructure";
import Machinelearning from "../partials/ourexpertise/machinelearning";
import Businessintelligence from "../partials/ourexpertise/businessintelligence";

// let sections = [
//   {
//     key: 1,
//     name: "Data Engineering",
//     Icon: DataEngineeringIcon,
//   },
//   {
//     key: 2,
//     name: "Business Intelligence",
//     Icon: BusinessIntelligenceIcon,
//   },
//   {
//     key: 3,
//     name: "Machine Learning",
//     Icon: MachineLearningIcon,
//   },
//   {
//     key: 4,
//     name: "Infrastructure",
//     Icon: InfrastructureIcon,
//   },
// ];

function OurExpertise() {
  const [selectedsection, setselectedsection] = useState("Data Engineering");
  // eslint-disable-next-line
  const [hidedropdown, sethidedropdown] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/expertise") {
      sethidedropdown(true);
      if (location.query) {
        setselectedsection(location.query.selected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.query]);
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
        {/*  Page illustration */}
        <div
          className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1"
          aria-hidden="true"
        >
          <PageIllustration />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 md:px-0">
            <div className="pt-32 py-8 md:pt-40">
              {/* <div className=" grid gap-4 grid-cols-2 md:grid-cols-4 md:gap-1">
                {sections.map(({ name, Icon }) => (
                  <div
                    key={name}
                    onClick={() => setselectedsection(name)}
                    className={`flex flex-row cursor-pointer items-center justify-center space-x-2 p-2 ${
                      selectedsection === name
                        ? "border-r border-t border-l border-teal-600"
                        : ""
                    }`}
                  >
                   
                    <span className="text-xl">{name}</span>
                  </div>
                ))}
              </div> */}
              {/* <div className="flex items-center justify-center">
                <div className="border-b border-teal-600 w-10/12 pt-2"></div>
              </div> */}
              <div>
                {selectedsection === "Data Engineering" ? (
                  <Dataengineering />
                ) : (
                  ""
                )}
                {selectedsection === "Business Intelligence" ? (
                  <Businessintelligence />
                ) : (
                  ""
                )}
                {selectedsection === "Machine Learning" ? (
                  <Machinelearning />
                ) : (
                  ""
                )}
                {selectedsection === "Infrastructure" ? <Infrastructure /> : ""}
              </div>
            </div>
          </div>
        </section>
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default OurExpertise;
