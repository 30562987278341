import React, { useEffect, lazy, Suspense } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./css/style.scss";

import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";

import Home from "./pages/Home";
// import Blog from './pages/Blog';
// import About from './pages/About';
// import Contact from './pages/Contact';
// import Policy from './pages/Policy';
// import Terms from './pages/Terms';
// import PageNotFound from './pages/PageNotFound';
// import ReactGa from "react-ga";
import OurExpertise from "./pages/OurExpertise";
import Blog from "./pages/Blog";

// const Blog = lazy(()=>import('./pages/Blog'))
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
const Policy = lazy(() => import("./pages/Policy"));
const Terms = lazy(() => import("./pages/Terms"));
const PageNotFound = lazy(() => import("./pages/PageNotFound"));
// const WhatWeDo = lazy(()=>import('./pages/WhatWeDo'))
const Career = lazy(() => import("./pages/Career"));

// ReactGa.initialize("UA-255549954-1");

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 750,
      easing: "ease-out-quart",
    });
  });

  useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: location.pathname,
        title: location.pathname,
      }
    });
    console.log(window.dataLayer)
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
    focusHandling("outline");
    // ReactGa.pageview(location.pathname);
  }, [location.pathname]); // triggered on route change


  const renderLoader = () => <p></p>;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Zeza Technologies</title>
        <link rel="canonical" href="https://zeza.tech/" />
        <meta name="description" content="Make data your competitive advantage" />
        <meta name="description" content="We build and scale data-intensive robust applications" />
      </Helmet>
      <Suspense fallback={renderLoader()}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          {/* <Route exact path="/whatwedo">
          <WhatWeDo />
        </Route> */}
          {/* <Route path="/blog">
          <Blog />
        </Route> */}
          <Route path="/career">
            <Career />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/policy">
            <Policy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/expertise">
            <OurExpertise />
          </Route>
          <Route path="/blogs">
            <Blog />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Suspense>
    </>
  );
}

export default App;
