import React from "react";
import Team1Icon from "../images/Values/1 team.png";
import Mindset10xIcon from "../images/Values/10x mindset.png";
import DetailOriented from "../images/Values/detail oriented.png";
import CustomerSuccessOriented from "../images/Values/customer success oriented.png";
import ExcellentCommunication from "../images/Values/excellent communication.png";
import GoingAboveAndBeyond from "../images/Values/going above and beyond.png";

function Values() {
  const data = [
    {
      heading: "Customer First",
      description: `Customer empathy is behind every aspect of our business. We think like customers and act like owners.`,
      Icon: CustomerSuccessOriented,
      color: "teal",
      colorSaturation: 500,
      size: "3.0rem",
      styles: { transform: "translateY(0.3rem)" },
    },
    {
      heading: "Excellent Communication",
      description: `Good communication solves most problems. If we are to make a mistake, we'd rather make a mistake of over communication and not under.`,
      Icon: ExcellentCommunication,
      color: "teal",
      colorSaturation: 500,
      size: "3.0rem",
      styles: {},
    },
    {
      heading: "Detail Oriented",
      description: `We sweat the details. Details are the nuts and bolts of an idea or plan. Details are the little things that make the difference.`,
      Icon: DetailOriented,
      color: "teal",
      colorSaturation: 500,
      size: "3.0rem",
      styles: { transform: "translateX(0.3rem)" },
    },
    {
      heading: "One Team",
      description: `We unite to deliver results for our company and customers. We win together.`,
      Icon: Team1Icon,
      color: "teal",
      colorSaturation: 500,
      size: "3.5rem",
      styles: {},
    },
    {
      heading: "10X Mindset",
      description: `Thinking 10x - 10x Goals, 10x Growth, 10x Results. We start with "What if?" and end with "Why not?" `,
      Icon: Mindset10xIcon,
      color: "teal",
      colorSaturation: 500,
      size: "3.5rem",
      styles: { transform: "translateY(-0.3rem)" },
    },
    {
      heading: "Going Above and Beyond",
      description: `We go the extra mile by delivering a "WOW" experience.`,
      Icon: GoingAboveAndBeyond,
      color: "teal",
      colorSaturation: 500,
      size: "3.5rem",
      styles: { transform: "translateY(0.3rem)" },
    },
  ];

  let iconStyles = {
    marginInline: "auto",
    display: "grid",
    placeContent: "center",
  };
  return (
    <section
      style={{
        minHeight: "min(47rem, 100vh)",
        display: "grid",
        placeContent: "center",
        scrollSnapAlign: "start",
      }}
      className="relative md:mb-10 min-h-screen"
      id="services"
    >
      <div className="py-12 mt-12 md:py-10 ">
        <div className="max-w-3xl mx-auto text-center pb-8 ">
          <h1 className="h2 text-3xl md:text-4xl mb-4">Our Values</h1>
        </div>
        <div className="max-w-84rem mx-auto  px-4 sm:px-6">
          <div className="service-row">
            <div
              style={{ justifyItems: "center" }}
              className="md:px-5 md:grid md:grid-cols-12"
            >
              {data.map(
                ({
                  heading,
                  description,
                  Icon,
                  color,
                  colorSaturation,
                  size,
                  styles,
                }) => (
                  <div
                    key={heading}
                    className="text-center md:px-1 service-tile mx-4 mb-14 md:col-span-4 lg:col-span-4"
                  >
                    <div
                      className="relative mb-4 w-20 h-20"
                      style={iconStyles}
                      //
                    >
                      <img
                        src={Icon}
                        alt=""
                        loading="lazy"
                        // className={`w-${size} h-${size} `}

                        style={{
                          ...styles,
                          height: size,
                          width: size,

                          // , backgroundColor:'red'
                        }}
                      />
                      <div
                        className={`absolute inset-0 rounded-full opacity-30 bg-gradient-to-tr from-teal-500 -z-1 `}
                        aria-hidden="true"
                        style={{ border: "2px solid teal" }}
                      ></div>
                    </div>
                    <p className="text-2xl font-semibold text-white pb-4 pt-2">
                      {heading}
                    </p>
                    <p className=" text-base text-gray-400 dark:text-gray-400">
                      {description}
                    </p>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="absolute" style={{height:'50%', width:'100%', bottom: 0, scrollSnapAlign: 'end'}}></div>                                          */}
    </section>
  );
}

export default Values;
