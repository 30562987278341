import BusinessIntelligenceIcon from "../images/Services/Artboard1.png";
import DataEngineeringIcon from "../images/Services/Artboard2.png";
import MachineLearningIcon from "../images/Services/Artboard3.png";
import InfrastructureIcon from "../images/Services/Artboard4.png";
import { Link } from "react-router-dom";
// import ProductDevelopmentIcon from '../images/Services/product-engineering.png'

let data = [
  {
    key: 1,
    name: "Data Engineering",
    Icon: DataEngineeringIcon,
    Description:
      "We design, architect and implement the data infrastructure to enable you to use data most effectively.",
    Tags: [
      { title: "Data Lake" },
      { title: "Data Warehouse" },
      { title: "Data Migrations" },
      { title: "ETL/ELT Pipelines" },
    ],
  },
  {
    key: 2,
    name: "Business Intelligence",
    Icon: BusinessIntelligenceIcon,
    Description:
      "We create dashboards to explore, analyze and track business analytics to make data driven decisions.",
    Tags: [
      { title: "Alerts" },
      { title: "Reports" },
      { title: "Interactive Dashboards" },
    ],
  },

  {
    key: 3,
    name: "Machine Learning",
    Icon: MachineLearningIcon,
    Description:
      "We implement end to end machine learning applications with a focus on performance and cost optimization.",
    Tags: [
      { title: "MLOps" },
      { title: "Computer Vision (CV)" },
      { title: "Tabular (Classical/Relational)" },
      { title: "Natural Language Processing (NLP)" },
    ],
  },
  {
    key: 4,
    name: "Infrastructure",
    Icon: InfrastructureIcon,
    Description:
      "We automate developer operations to improve productivity and granular visibility into the infrastructure.",
    Tags: [
      { title: "GitOps (CI/CD)" },
      { title: "Infrastructure as Code" },
      { title: "Logging, Alerting & Monitoring" },
    ],
  },
  // { key: 5, name: 'Product Development', Icon: ProductDevelopmentIcon }
];
export default function Services() {
  return (
    <section
      style={{
        placeContent: "center",
        scrollSnapAlign: "start",
        paddingBottom: "10vh",
      }}
      className="relative"
      id="process"
    >
      <div className="  px-4 sm:px-6">
        <div className="md:flex md:justify-center md:items-center md:flex-col py-12 md:py-32 ">
          <div className="max-w-4xl text-center pb-12 ">
            <h1 className="h2 text-3xl md:text-4xl mb-4 md:mb-8">
              We specialize in
            </h1>
            <p className="text-lg md:text-xl font-medium text-gray-400 dark:text-gray-400 mb-4">
              Data, Machine Learning and Distributed Systems
            </p>
          </div>
          {/* Desktop View Design */}
          <div style={{ maxWidth: "88rem" }} className=" px-4 sm:px-0">
            <div className="service-row">
              <div className=" flex flex-row overflow-x-scroll  md:overflow-hidden  md:grid  md:grid-cols-2 xl:grid-cols-4 gap-6 gap-x-4  ">
                {data.map(({ name, Icon, key, Description, Tags }) => (
                  <Link
                    className="inline-block focus:outline-none "
                    key={key}
                    to={{
                      pathname: "/expertise",
                      query: { selected: name },
                    }}
                  >
                    <main className="border border-gray-600 group cursor-pointer bg-gray-800  hover:bg-gray-700   hover:border-teal-400 h-128 md:h-auto rounded-3xl w-72 md:w-full">
                      <div className="flex flex-col items-center justify-evenly space-y-5 py-4">
                        <img
                          src={Icon}
                          alt="icon"
                          className="w-20 h-20"
                          loading="lazy"
                        />
                        <span className="text-2xl font-semibold text-white group-hover:text-teal-400 pb-2">
                          {name}
                        </span>
                        <div className="w-11/12">
                          <span className="text-lg font-normal text-white">
                            {Description}
                          </span>
                        </div>
                        <div className="flex items-center justify-around w-11/12 h-40">
                          <div className="grid grid-cols-1 gap-2 pt-2  gap-y-2 w-full">
                            {Tags.map((tag, i) => (
                              <ul
                                key={i}
                                className="list-none  rounded-lg flex items-center justify-start"
                              >
                                <li
                                  className="text-white font-light text-base tracking-tighter  px-2 py-1 w-full text-center  "
                                  key={tag.title}
                                >
                                  {tag.title}
                                </li>
                              </ul>
                            ))}
                          </div>
                        </div>
                      </div>
                    </main>
                  </Link>
                ))}
              </div>
            </div>
          </div>

          {/* Mobile View Design */}
          {/* <div className="max-w-7xl  px-4 sm:px-0 block sm:hidden">
            <div className="service-row">
              <div className="md:grid md:grid-cols-2 gap-6 gap-x-4 space-y-5">
                {data.map(({ name, Icon, key, Description }) => (
                  <main className="border border-red h-auto rounded-3xl p-5">
                    <div className="flex items-center justify-center space-x-2">
                      <img
                        src={Icon}
                        alt=""
                        className="w-12 h-12 "
                        width="90"
                        height="90"
                        loading="lazy"
                      />
                      <span className="text-xl font-semibold text-white pb-2">
                        {name}
                      </span>
                    </div>
                    <div className="">
                      <span className="w-10/12 ">{Description}</span>
                    </div>
                  </main>
                ))}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
