import React from "react";
import kafka from "../../../images/Peekinourtoolkit/Artboard 68.png";
import mongo from "../../../images/Peekinourtoolkit/Artboard 69.png";
import Scala from "../../../images/Peekinourtoolkit/Artboard 71.png";
import Pandas from "../../../images/Peekinourtoolkit/Artboard 72.png";
import prefect from "../../../images/Peekinourtoolkit/Artboard 70.png";
import ansible from "../../../images/Peekinourtoolkit/Artboard 75.png";
import argo from "../../../images/Peekinourtoolkit/Artboard 65.png";
import consul from "../../../images/Peekinourtoolkit/Artboard 62.png";
import drone from "../../../images/Peekinourtoolkit/Artboard 63.png";
import github from "../../../images/Peekinourtoolkit/Artboard 64.png";
import promethus from "../../../images/Peekinourtoolkit/Artboard 66-1.png";
import terragrunt from "../../../images/Peekinourtoolkit/Artboard 67.png";
import traefik from "../../../images/Peekinourtoolkit/Artboard 74.png";
import packer from "../../../images/Peekinourtoolkit/Artboard 73.png";

let toolkit3 = [
  { logo: consul, name: "consul", url: "https://www.consul.io/" },
  { logo: drone, name: "drone", url: "https://www.drone.io/" },
  { logo: github, name: "github", url: "https://github.com/" },
  { logo: argo, name: "argo", url: "https://argoblockchain.com/" },
  { logo: promethus, name: "promethus", url: "https://prometheus.io/" },
  {
    logo: terragrunt,
    name: "terragrunt",
    url: "https://terragrunt.gruntwork.io/",
  },
  { logo: ansible, name: "ansible", url: "https://www.ansible.com/" },
  { logo: kafka, name: "kafka", url: "https://kafka.apache.org/" },
  {
    logo: mongo,
    name: "mongoDB",
    url: "https://www.mongodb.com/",
  },
  {
    logo: prefect,
    name: "prefect",
    url: "https://www.prefect.io/",
  },
  {
    logo: Scala,
    name: "Scala",
    url: "https://www.scala-lang.org/",
  },
  {
    logo: Pandas,
    name: "Pandas",
    url: "https://pandas.pydata.org/",
  },
  { logo: packer, name: "packer", url: "https://www.packer.io/" },
  { logo: traefik, name: "traefik", url: "https://traefik.io/" },
  { logo: null },
  { logo: null },
  { logo: null },
  { logo: null },
  { logo: null },
  { logo: null },
];

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

function peekinourtoolkit4() {
  return (
    <div className="grid justify-items-center gap-x-8 gap-y-16 grid-cols-5">
      {toolkit3.map(({ logo, url, name }) => {
        return logo !== null ? (
          <div
            onClick={() => openInNewTab(url)}
            key={url}
            className="w-14 h-14 cursor-pointer"
          >
            <div className="flex flex-col items-center justify-center group">
              <img
                src={logo}
                alt="logo"
                className="transform hover:scale-110 motion-reduce:transform-none"
              />
              <span className=" pt-1 capitalize opacity-100 text-sm font-semibold text-gray-400 dark:text-gray-400">
                <nobr>{name}</nobr>
              </span>
            </div>
          </div>
        ) : (
          <div className="w-14 h-14"></div>
        );
      })}
    </div>
  );
}

export default peekinourtoolkit4;
