import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="relative" style={{ scrollSnapAlign: "start" }}>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-12 md:py-0 md:pt-8 border-t border-gray-200 dark:border-gray-800 -mt-px">
          {/* Footer illustration */}
          <div className="pointer-events-none -z-1" aria-hidden="true">
            <svg
              className="absolute bottom-0 left-0 transform -translate-x-1/2 ml-24 dark:opacity-40"
              width="800"
              height="264"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="400"
                cy="400"
                r="400"
                fill="url(#footerglow_paint0_radial)"
                fillOpacity=".4"
              />
              <defs>
                <radialGradient
                  id="footerglow_paint0_radial"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="rotate(90 0 400) scale(315.089)"
                >
                  <stop stopColor="#3ABAB4" />
                  <stop offset="1" stopColor="#3ABAB4" stopOpacity=".01" />
                </radialGradient>
              </defs>
            </svg>
          </div>

          <Link className="inline-block pl-5" to="/" aria-label="Cruip">
            <img
              style={{ transform: "scale(2)" }}
              className="w-8 h-6 md:hidden"
              src={require("../images/zeza_logo.png").default}
              width="68"
              height="68"
              alt="zezaLogo"
            />
            <svg className="w-8 h-8"></svg>
          </Link>
          {/* Top area: Blocks */}
          <div className="hidden md:grid md:grid-cols-12 gap-8 lg:gap-20 ">
            {/* 1st block */}
            <div className="md:col-span-2 lg:col-span-3">
              {/* Logo */}
              <Link className="inline-block pl-5" to="/" aria-label="Cruip">
                <img
                  style={{ transform: "scale(2)" }}
                  className="w-full h-full md:w-10 md:h-8"
                  src={require("../images/zeza_logo.png").default}
                  width="68"
                  height="68"
                  alt="zezaLogo"
                />
                <svg className="w-8 h-8"></svg>
              </Link>
            </div>

            {/* 2nd, 3rd, 4th and 5th blocks for desktop*/}
            <div className="col-start-3 col-end-11 md:col-start-3 lg:col-start-5 xl:col-start-6 md:col-end-13 flex gap-6 justify-evenly invisible sm:visible">
              {/* 2nd block */}
              <div className="flex flex-col items-end">
                {/* md:text-right */}
                <div className="text-sm  ">
                  <Link
                    className="inline-block focus:outline-none"
                    to="/expertise"
                  >
                    <p className="font-medium mb-2">Expertise</p>
                  </Link>
                </div>
                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/expertise",
                      query: { selected: "Data Engineering" },
                    }}
                  >
                    Data Engineering
                  </Link>
                </div>
                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/expertise",
                      query: { selected: "Machine Learning" },
                    }}
                  >
                    Machine Learning
                  </Link>
                </div>
                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/expertise",
                      query: { selected: "Business Intelligence" },
                    }}
                  >
                    Business Intelligence
                  </Link>
                </div>
                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/expertise",
                      query: { selected: "Infrastructure" },
                    }}
                  >
                    Infrastructure
                  </Link>
                </div>
                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/",
                      query: { selected: "toolkit" },
                    }}
                  >
                    Our toolkit
                  </Link>
                </div>
              </div>

              <div className="flex flex-col items-end">
                {/* md:text-right */}
                <div className="text-sm">
                  <Link className="inline-block focus:outline-none" to="/about">
                    <p className="font-medium mb-2">About</p>
                  </Link>
                </div>

                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/about",
                      query: { selected: "whoweare" },
                    }}
                  >
                    Who we are
                  </Link>
                </div>
                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/about",
                      query: { selected: "operatingprinciples" },
                    }}
                  >
                    Principles
                  </Link>
                </div>
                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/",
                      query: { selected: "values" },
                    }}
                  >
                    Our Values
                  </Link>
                </div>
                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/",
                      query: { selected: "testimonials" },
                    }}
                  >
                    Testimonials
                  </Link>
                </div>
              </div>

              <div className="flex flex-col items-end">
                {/* md:text-right */}
                <div className="text-sm">
                  <Link
                    className="inline-block focus:outline-none"
                    to="/career"
                  >
                    <p className="font-medium mb-2 ">Company</p>
                  </Link>
                </div>

                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/career",
                      query: { selected: "whyjoinus" },
                    }}
                  >
                    Why Join Us?
                  </Link>
                </div>
                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/career",
                      query: { selected: "lifeatzeza" },
                    }}
                  >
                    Life at Zeza
                  </Link>
                </div>
                <div className="text-sm text-gray-500 hover:text-white cursor-pointer">
                  <Link
                    to={{
                      pathname: "/about",
                      query: { selected: "ourteam" },
                    }}
                  >
                    Our Team
                  </Link>
                </div>
                <div className="text-sm text-teal-400 hover:text-white cursor-pointer">
                  <div className="flex bg-teal-600 bg-opacity-20 px-1.5 rounded-lg">
                    <Link
                      to={{
                        pathname: "/career",
                        query: { selected: "growwithus" },
                      }}
                    >
                      We're Hiring
                    </Link>
                  </div>
                </div>
              </div>

              <div className="flex flex-col items-end">
                {/* md:text-right */}
                <div className="text-sm">
                  <Link className="inline-block" to="/blogs">
                    <p className="font-medium mb-2">Blog</p>
                  </Link>
                </div>

                <div className="text-sm">
                  <Link className="inline-block" to="/contact">
                    <p className="font-medium mb-2">Contact</p>
                  </Link>
                </div>
              </div>
            </div>

            {/* 2nd, 3rd, 4th and 5th blocks for mobile*/}
          </div>
          <div
            className="absolute visible sm:invisible"
            style={{ top: "20px", right: "20px" }}
          >
            <div className="text-sm text-white hover:text-white cursor-pointer">
              <Link
                to={{
                  pathname: "/expertise",
                  query: { selected: "Data Engineering" },
                }}
              >
                Data Engineering
              </Link>
            </div>
            <div className="text-sm text-white hover:text-white cursor-pointer">
              <Link
                to={{
                  pathname: "/expertise",
                  query: { selected: "Machine Learning" },
                }}
              >
                Machine Learning
              </Link>
            </div>
            <div className="text-sm text-white hover:text-white cursor-pointer">
              <Link
                to={{
                  pathname: "/expertise",
                  query: { selected: "Business Intelligence" },
                }}
              >
                Business Intelligence
              </Link>
            </div>
            <div className="text-sm text-white hover:text-white cursor-pointer">
              <Link
                to={{
                  pathname: "/expertise",
                  query: { selected: "Infrastructure" },
                }}
              >
                Infrastructure
              </Link>
            </div>
          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between pt-12">
            {/* Social links */}
            <ul className="flex mb-4 pr-20 md:order-1 ">
              <li className="space-x-2">
                <a
                  className="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out"
                  href="https://twitter.com/zeza_tech"
                  aria-label="Twitter"
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M24 11.5c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4 0 1.6 1.1 2.9 2.6 3.2-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H8c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4c.7-.5 1.3-1.1 1.7-1.8z" />
                  </svg>
                </a>
              </li>
              {/* <li className="ml-4">
                                <a className="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out" href="#0" aria-label="Github">
                                    <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z" />
                                    </svg>
                                </a>
                            </li> */}
              {/* <li className="ml-4">
                                <a className="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out" href="#0" aria-label="Facebook">
                                    <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                                    </svg>
                                </a>
                            </li> */}
              {/* <li className="ml-4">
                                <a className="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out" href="#0" aria-label="Instagram">
                                    <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="20.145" cy="11.892" r="1" />
                                        <path d="M16 20c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
                                        <path d="M20 24h-8c-2.056 0-4-1.944-4-4v-8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zm-8-14c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2v-8c0-.935-1.065-2-2-2h-8z" />
                                    </svg>
                                </a>
                            </li> */}
              <li className="ml-4">
                <a
                  className="flex justify-center items-center text-white bg-teal-500 dark:text-teal-500 dark:bg-gray-800 hover:underline hover:bg-teal-600 rounded-full transition duration-150 ease-in-out ml-3"
                  href="https://www.linkedin.com/company/zeza-tech"
                  aria-label="Linkedin"
                >
                  <svg
                    className="w-8 h-8 fill-current"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M23.3 8H8.7c-.4 0-.7.3-.7.7v14.7c0 .3.3.6.7.6h14.7c.4 0 .7-.3.7-.7V8.7c-.1-.4-.4-.7-.8-.7zM12.7 21.6h-2.3V14h2.4v7.6h-.1zM11.6 13c-.8 0-1.4-.7-1.4-1.4 0-.8.6-1.4 1.4-1.4.8 0 1.4.6 1.4 1.4-.1.7-.7 1.4-1.4 1.4zm10 8.6h-2.4v-3.7c0-.9 0-2-1.2-2s-1.4 1-1.4 2v3.8h-2.4V14h2.3v1c.3-.6 1.1-1.2 2.2-1.2 2.4 0 2.8 1.6 2.8 3.6v4.2h.1z" />
                  </svg>
                </a>
              </li>
            </ul>
            {/* Middle links */}
            <div className="text-sm md:order-2 text-gray-700 mb-2 md:mb-0 space-x-2">
              <Link
                className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                to="/terms"
              >
                Terms
              </Link>

              <Link
                className="text-gray-600 dark:text-gray-400 hover:underline transition duration-150 ease-in-out"
                to="/policy"
              >
                Privacy Policy
              </Link>
            </div>
            {/* Copyrights note */}
            <div className="text-gray-600 dark:text-gray-400 text-sm -mr-20 pb-2">
              &copy; {new Date().getFullYear()} Zeza Technologies. All rights
              reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
